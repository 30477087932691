import { MUI_MODAL_Z_INDEX } from '@asset/enum/common';
import RefreshIcon from '@asset/svg/RefreshIcon';
import IconContainer from '@components/common/IconContainer';
import LoadingScreen from '@components/common/loading/LoadingScreen';
import { useChatStore } from '@store/chat';
import _ from 'lodash';
import React, { useState } from 'react';

const QuestionChatDetailModal = () => {
  const { questionDetailUrl, setQuestionDetailUrl } = useChatStore();
  const [loadSuccessQuestionDetail, setLoadSuccessQuestionDetail] =
    useState<boolean>(false);
  const [questionDetailModalKey, setQuestionDetailModalKey] =
    useState<number>(0);
  const closeIframeModal = () => {
    setQuestionDetailUrl(null);
    setLoadSuccessQuestionDetail(false);
  };
  if (!_.isNil(questionDetailUrl))
    return (
      <div
        className="fixed top-0 left-0 modal"
        style={{ zIndex: MUI_MODAL_Z_INDEX }}
      >
        <div className="flex flex-col modal-content w-[100dvw] h-[100dvh]">
          <div className="flex items-center justify-between px-3 py-1 bg-my-gray-30">
            <div className="flex items-center gap-3">
              <div
                className="cursor-pointer text-label-lg-500 text-my-gray-100"
                onClick={closeIframeModal}
              >
                닫기
              </div>
            </div>
            <div className="flex items-center">
              <IconContainer
                icon={
                  <RefreshIcon color="var(--gray-100)" width="24" height="24" />
                }
                size={'M'}
                darkMode
                onClick={() => {
                  setLoadSuccessQuestionDetail(false);
                  setQuestionDetailModalKey((prev) => prev + 1);
                }}
              ></IconContainer>
            </div>
          </div>
          <div className="relative flex-grow w-full bg-my-gray-100">
            <iframe
              key={questionDetailModalKey}
              src={questionDetailUrl}
              onLoad={() => setLoadSuccessQuestionDetail(true)}
              className="w-full h-full"
            ></iframe>
            {!loadSuccessQuestionDetail && <LoadingScreen />}
          </div>
        </div>
      </div>
    );
};

export default QuestionChatDetailModal;
