import { DateTime } from 'luxon';

export const convertTimeToText = (dateTime: DateTime, locale: 'ko' | 'en') => {
  const now = DateTime.now();
  const diffDays = Math.floor(Math.abs(now.diff(dateTime, 'days').days));

  if (diffDays <= 0) {
    const diffMinutes = now.diff(dateTime, 'minutes').minutes;
    if (diffMinutes < 1) {
      return '방금';
    } else {
      return dateTime.toFormat('t', { locale });
    }
  } else if (diffDays < 8) {
    return `${diffDays}일 전`;
  } else {
    return dateTime.toFormat('yyyy. MM. dd');
  }
};
