import { postFile } from '@apis/api/chat';
import { UserType } from '@asset/enum/UserEnum';
import { ChannelMetaData } from '@asset/type/chat';
import { MetaData } from '@sendbird/chat';
import _ from 'lodash';
import SendbirdChat, { SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { getMessaging, getToken } from 'firebase/messaging';

export const uploadChatFiles = async (
  files: File[],
  onFail: () => void,
): Promise<{ url: string }[]> => {
  const fileUrls: { url: string }[] = [];
  let isFailUploadFile = false;
  for (const file of files) {
    await postFile(file)
      .then((res) => fileUrls.push({ url: res.data.url }))
      .catch(() => (isFailUploadFile = true));
  }
  if (isFailUploadFile) {
    onFail();
  }
  return fileUrls;
};

export const addTypeChannelMetaData = (
  metaDataObject: MetaData,
): ChannelMetaData => {
  return {
    isMainClass: Boolean(metaDataObject.isMainClass),
    studentId: Number(metaDataObject.studentId),
    studentUserId: metaDataObject.studentUserId,
    teacherId: Number(metaDataObject.teacherId),
    teacherUserId: metaDataObject.teacherUserId,
  };
};

export const getUsernameFromNickname = (nickname: string) =>
  nickname.replace('[T] ', '').replace('[S] ', '');

export const getIsVisibleChatButton = (
  sendbirdUserInfo: {
    userId: string;
    accessToken: string;
    currentClassChannelUrl: string;
  },
  userType: UserType,
  userLanguage: string,
) => {
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;
  let isVisibleChatButton = false;
  if (!_.isNil(sendbirdUserId) && !_.isNil(sendbirdAccessToken)) {
    if (userType === UserType.STUDENT) {
      if (
        !_.isNil(sendbirdUserInfo?.currentClassChannelUrl) &&
        userLanguage === 'ko'
      ) {
        isVisibleChatButton = true;
      }
    } else if (userType === UserType.TEACHER) {
      isVisibleChatButton = true;
    }
  }

  return isVisibleChatButton;
};

export const withSendbird = async (
  sendbirdUserId: string,
  sendbirdAccessToken: string,
  callback: (sb: SendbirdChatWith<GroupChannelModule[]>) => Promise<void>,
) => {
  const sb = SendbirdChat.init({
    appId: process.env.NEXT_PUBLIC_SEND_BIRD_APP_ID,
    modules: [new GroupChannelModule()],
  });

  try {
    await sb.connect(sendbirdUserId, sendbirdAccessToken);
    await callback(sb);
  } finally {
    await sb.disconnect();
  }
};

async function retryGetDeviceToken(retries: number): Promise<string> {
  try {
    const messaging = getMessaging();
    return await getToken(messaging);
  } catch (error) {
    if (retries === 0) {
      console.error('getToken 최대 재시도 횟수 초과:', error);
      throw error;
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return retryGetDeviceToken(retries - 1);
    }
  }
}

export const registerFCMToSendbird = async (
  sb: SendbirdChatWith<GroupChannelModule[]>,
) => {
  if (
    'Notification' in window &&
    Notification.permission === 'granted' &&
    typeof sb?.registerFCMPushTokenForCurrentUser === 'function'
  ) {
    const currentToken = await retryGetDeviceToken(5);
    if (currentToken) {
      // 마지막으로 접속한 기기에 대해서만 알림이 가도록 한다.
      // TODO 아래 부분을 매번 호출하는 것은 낭비처럼 보이기도 한다. 가능하면 1차 호출만 하도록 변경하자.
      await sb.unregisterFCMPushTokenAllForCurrentUser();
      await sb.registerFCMPushTokenForCurrentUser(currentToken);
    }
  }
};
