import { getSendbirdUserInfo } from '@apis/api/chat';
import { useUserInfoQuery } from '@apis/query/user';
import { registerFCMToSendbird, withSendbird } from '@asset/function/chat';
import ChatIcon, { ChatNotificationIcon } from '@asset/svg/ChatIcon';
import { useChatStore } from '@store/chat';
import { IconContainer } from '@styles/common/IconContainer.style';
import { getHoveredElementBackground } from '@styles/globalsStyles';
import _ from 'lodash';
import { useQuery } from 'react-query';

const GnbChatButton = ({
  iconColor = 'var(--gray-100)',
  backgroundColor = 'transparent',
  darkMode = false,
}: {
  iconColor?: string;
  backgroundColor?: string;
  darkMode?: boolean;
}) => {
  const { data: user } = useUserInfoQuery();
  const { isOpen, setIsOpen, unreadCount } = useChatStore();
  const { data: sendbirdUserInfo } = useQuery(
    ['GET_SENDBIRD_USER_INFO', user?.userId],
    () => getSendbirdUserInfo(user?.userId),
    {
      enabled: !_.isNil(user?.userId),
    },
  );
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;

  return (
    <IconContainer
      size="M"
      onClick={() => {
        setIsOpen(!isOpen);
        if (!('Notification' in window)) {
          return;
        }
        if (Notification.permission === 'granted') {
          return;
        }
        Notification.requestPermission().then(() => {
          withSendbird(
            sendbirdUserId,
            sendbirdAccessToken,
            registerFCMToSendbird,
          );
        });
      }}
      className={`flex items-center justify-center`}
      bgColor={backgroundColor}
      darkMode={darkMode}
      style={{
        background: isOpen
          ? darkMode
            ? 'var(--gray-30)'
            : getHoveredElementBackground(backgroundColor)
          : '',
      }}
    >
      {unreadCount > 0 ? (
        <ChatNotificationIcon
          width="1.5rem"
          height="1.5rem"
          color={iconColor}
        />
      ) : (
        <ChatIcon width="1.5rem" height="1.5rem" color={iconColor} />
      )}
    </IconContainer>
  );
};

export default GnbChatButton;
