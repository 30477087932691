import { StudyStep } from '@asset/enum/problemSolvingEnum';
import _ from 'lodash';
import qs from 'qs';
import { ParsedUrlQueryInput } from 'querystring';
import { UrlObject } from 'url';

const createQueryListToString = (
  key: string,
  values: (string | number)[],
): string => {
  if (_.isNil(values)) return null;
  return qs
    .stringify(
      { [key]: values },
      {
        arrayFormat: 'repeat',
        encodeValuesOnly: true,
      },
    )
    .replace(`${key}=`, '');
};

export const MY_CLASS_URL_PREFIX = '/manage/my-class';
export const MY_CLASS_MAIN_URL_PREFIX = `${MY_CLASS_URL_PREFIX}/main`;
export const MY_CLASS_WEEKLY_GOAL_URL_PREFIX = `${MY_CLASS_URL_PREFIX}/weekly-goal`;

export const MANAGE_QUESTION_DETAIL_URL_PREFIX =
  '/manage/student/question/detail';
export const getManageQuestionDetailPageUrlObject = (
  query: (
    | { problemStatusDetailId: number; pageId: number }
    | {
        myStudyContentId: number;
        pageId: number;
        studyStatusType: StudyStep;
      }
  ) & {
    reportId: number;
    pageNumber?: number;
    studentIds?: number[];
    teacherIds?: number[];
    studyMaterialIds?: number[];
    studiedAt?: 'desc' | 'asc';
    status?: ('not-resolved' | 'resolving' | 'resolved')[];
  },
): UrlObject => {
  const studentQueryString = createQueryListToString(
    'studentName',
    query.studentIds,
  );
  const teacherQueryString = createQueryListToString(
    'teacherName',
    query.teacherIds,
  );
  const materialQueryString = createQueryListToString(
    'studyMaterialName',
    query.studyMaterialIds,
  );
  const statusQueryString = createQueryListToString('status', query.status);

  const urlString = `${MANAGE_QUESTION_DETAIL_URL_PREFIX}/`;

  const _query = {
    reportId: query.reportId,
    pageNumber: query.pageNumber,
    studiedAt: query.studiedAt,
    studentName: studentQueryString,
    teacherName: teacherQueryString,
    studyMaterialName: materialQueryString,
    status: statusQueryString,
  };
  _query['pageId'] = query.pageId;
  if ('problemStatusDetailId' in query) {
    _query['problemStatusDetailId'] = query.problemStatusDetailId;
  } else if ('myStudyContentId' in query) {
    _query['myStudyContentId'] = query.myStudyContentId;
    _query['studyStatusType'] = query.studyStatusType;
  }

  return {
    pathname: urlString,
    query: qs.parse(
      qs.stringify(_query, { skipNulls: true }),
    ) as ParsedUrlQueryInput,
  };
};
