export const DeleteIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.66667 14C4.3 14 3.98612 13.8694 3.72501 13.6083C3.46389 13.3472 3.33334 13.0333 3.33334 12.6667V4C3.14445 4 2.98612 3.93611 2.85834 3.80833C2.73056 3.68056 2.66667 3.52222 2.66667 3.33333C2.66667 3.14444 2.73056 2.98611 2.85834 2.85833C2.98612 2.73056 3.14445 2.66667 3.33334 2.66667H6C6 2.47778 6.06389 2.31944 6.19167 2.19167C6.31945 2.06389 6.47778 2 6.66667 2H9.33334C9.52223 2 9.68056 2.06389 9.80834 2.19167C9.93612 2.31944 10 2.47778 10 2.66667H12.6667C12.8556 2.66667 13.0139 2.73056 13.1417 2.85833C13.2694 2.98611 13.3333 3.14444 13.3333 3.33333C13.3333 3.52222 13.2694 3.68056 13.1417 3.80833C13.0139 3.93611 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5361 13.3472 12.275 13.6083C12.0139 13.8694 11.7 14 11.3333 14H4.66667ZM11.3333 4H4.66667V12.6667H11.3333V4ZM6 11.3333H7.33334V5.33333H6V11.3333ZM8.66667 11.3333H10V5.33333H8.66667V11.3333Z"
        fill={color}
      />
    </svg>
  );
};
