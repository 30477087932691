import { useState } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import {
  MenuItem,
  MessageMenu,
  MessageMenuProps,
  useMessageMenuContext,
} from '@sendbird/uikit-react/ui/MessageMenu';
import Dialog from '@components/common/modal/Dialog';
import * as S from '@styles/chat.style';
import CornerDownRightIcon from '@asset/svg/CornerDownRightIcon';
import EditIcon from '@asset/svg/EditIcon';
import { DeleteIcon } from '@asset/svg/DeleteIcon';
import AnnouncementIcon from '@asset/svg/AnnouncementIcon';
import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import { ChatMessageMenu, ChatMessageType } from '@asset/enum/chatEnum';
import { useChatStore } from '@store/chat';

const CustomMessageMenuButton = ({
  menuProps,
}: {
  menuProps: MessageMenuProps;
}) => {
  const { data: user } = useUserInfoQuery();
  const { setClickedMessageToReply } = useChatStore();
  const { message, isByMe } = menuProps;
  const isFileMessage =
    message.isFileMessage() || message.isMultipleFilesMessage();
  const isRepliedMessage = message.parentMessageId !== 0;

  const menuItems: ChatMessageMenu[] = [];
  if (!isRepliedMessage && message.customType !== ChatMessageType.QUESTION) {
    menuItems.push(ChatMessageMenu.REPLY);
  }
  if (
    user?.type === UserType.TEACHER &&
    !isFileMessage &&
    message.customType !== ChatMessageType.QUESTION
  ) {
    menuItems.push(ChatMessageMenu.ANNOUNCE);
  }
  if (isByMe) {
    if (!isFileMessage && message.customType !== ChatMessageType.QUESTION) {
      menuItems.push(ChatMessageMenu.EDIT);
    }
    menuItems.push(ChatMessageMenu.DELETE);
  }

  if (menuItems.length === 0) return <></>;

  return (
    <S.ChatMessageMenuButton>
      <MessageMenu
        {...menuProps}
        renderMenuItems={(props) => {
          const { messageInputRef } = useGroupChannelContext();
          const { ReplyMenuItem, EditMenuItem } = props.items;

          const messageMenuMap = {
            [ChatMessageMenu.REPLY]: (
              <ReplyMenuItem
                onClick={() => {
                  messageInputRef.current.focus();
                  setClickedMessageToReply(message);
                }}
              >
                <CornerDownRightIcon color={'var(--gray-10)'} />
                답장
              </ReplyMenuItem>
            ),
            [ChatMessageMenu.ANNOUNCE]: (
              <CustomAnnounceMenuItem menuProps={menuProps} />
            ),
            [ChatMessageMenu.EDIT]: (
              <EditMenuItem>
                <EditIcon
                  width={'1rem'}
                  height={'1rem'}
                  color={'var(--gray-10)'}
                />
                수정
              </EditMenuItem>
            ),
            [ChatMessageMenu.DELETE]: <CustomDeleteMenuItem />,
          };
          return <>{menuItems.map((menuItem) => messageMenuMap[menuItem])}</>;
        }}
      ></MessageMenu>
    </S.ChatMessageMenuButton>
  );
};

export default CustomMessageMenuButton;

const CustomAnnounceMenuItem = ({
  menuProps,
}: {
  menuProps: MessageMenuProps;
}) => {
  const { message, channel } = menuProps;
  const { hideMenu } = useMessageMenuContext();

  const handleCreateNotification = async () => {
    try {
      await channel.pinMessage(message.messageId);
      channel.pinnedMessageIds.map((prevPinnedMessageId) => {
        if (prevPinnedMessageId !== message.messageId)
          channel.unpinMessage(prevPinnedMessageId);
      });
    } catch (error) {
      console.error('Error pinning message:', error);
    }
  };
  return (
    <MenuItem
      onClick={() => {
        handleCreateNotification();
        hideMenu();
      }}
    >
      <AnnouncementIcon />
      공지
    </MenuItem>
  );
};

const CustomDeleteMenuItem = () => {
  const { deleteMessage } = useGroupChannelContext();
  const { message, hideMenu, disableDeleteMessage } = useMessageMenuContext();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const disableDeleteMenu =
    typeof disableDeleteMessage === 'boolean'
      ? disableDeleteMessage
      : (message.threadInfo?.replyCount ?? 0) > 0;

  if (!disableDeleteMenu) {
    return (
      <>
        <MenuItem onClick={() => setOpenDeleteModal(true)}>
          <DeleteIcon />
          삭제
        </MenuItem>
        {openDeleteModal && (
          <Dialog
            title={'메시지 삭제'}
            content={[
              '메시지를 삭제할까요?',
              '다른 사람의 화면에서도 메시지가 삭제됩니다.',
            ]}
            buttons={[
              {
                color: 'gray',
                assetType: 'Secondary',
                content: '취소',
                onClickEvent: () => {
                  setOpenDeleteModal(false);
                  hideMenu();
                },
              },
              {
                color: 'red',
                assetType: 'Primary',
                content: '삭제',
                onClickEvent: () => deleteMessage(message),
              },
            ]}
          />
        )}
      </>
    );
  }
};
