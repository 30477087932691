import { CommonResponse } from '@apis/common/response';
import { authAPI, HandleError } from '@apis/core/instance';
import { ScheduledMessage } from '@asset/type/chat';
import { DateTime } from 'luxon';

export const getSendbirdUserInfo = async (
  userId: number,
): Promise<{
  userId: string;
  accessToken: string;
  currentClassChannelUrl: string;
}> => {
  const apiUrl = `/api/users/${userId}/sendbird-info/`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const createGoogleMeetSpace = async (
  config: {
    handleError: HandleError;
  } = null,
): Promise<
  CommonResponse & {
    data: {
      uri: string;
    };
  }
> => {
  const apiUrl = `/api/users/meetings/`;

  const data = await authAPI
    .post(apiUrl, null, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getGroupChannelsUrlContainsStudentName = async (
  studentNameContains: string,
): Promise<
  CommonResponse & {
    data: {
      channelUrl: string;
      studentId: number;
      studentName: string;
    }[];
  }
> => {
  const apiUrl = `/api/chat/sendbird/group-channels/?studentNameContains=${studentNameContains}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postFile = async (
  file: File,
): Promise<
  CommonResponse & {
    data: {
      url: string;
    };
  }
> => {
  const formData = new FormData();
  formData.append('file', file);
  const apiUrl = `/api/chat/files/`;

  const data = await authAPI
    .post(apiUrl, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getScheduledMessage = async (
  channelUrl: string,
): Promise<
  CommonResponse & {
    data: ScheduledMessage[];
  }
> => {
  const apiUrl = `/api/chat/sendbird/group-channels/${channelUrl}/scheduled-messages/`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postScheduledMessage = async (
  channelUrl: string,
  requestBody: {
    message: string;
    scheduledAt: DateTime;
    files: {
      url: string;
    }[];
  },
): Promise<CommonResponse> => {
  const apiUrl = `/api/chat/sendbird/group-channels/${channelUrl}/scheduled-messages/`;
  const _requestBody = JSON.parse(JSON.stringify(requestBody));
  _requestBody.scheduledAt = requestBody.scheduledAt.toISO();

  const data = await authAPI
    .post(apiUrl, _requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const patchScheduledMessage = async (
  scheduledMessageId: number,
  requestBody: {
    message?: string;
    scheduledAt?: DateTime;
    files?: {
      url: string;
    }[];
  },
): Promise<CommonResponse> => {
  const apiUrl = `/api/chat/sendbird/scheduled-messages/${scheduledMessageId}/`;

  const _requestBody = JSON.parse(JSON.stringify(requestBody));
  if (requestBody.scheduledAt) {
    _requestBody.scheduledAt = requestBody.scheduledAt.toISO();
  }

  const data = await authAPI
    .patch(apiUrl, _requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const deleteScheduledMessage = async (
  scheduledMessageId: number,
): Promise<CommonResponse> => {
  const apiUrl = `/api/chat/sendbird/scheduled-messages/${scheduledMessageId}/`;

  const data = await authAPI
    .delete(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
