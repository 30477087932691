import { useEffect } from 'react';
import GroupChannelListUI from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListUI';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';

import _ from 'lodash';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';

import { useChatStore, useGroupChannelListStore } from '@store/chat';
import { GroupChannelCustomType } from '@asset/type/chat';

import { getStudentsExtrasApi } from '@apis/api/student/common';
import { getStudentStudyStatus } from '@asset/function/commonFunctions';
import { convertTimeToText } from '@asset/function/timeFunctions';

import UserAvatarProfile from '@components/common/UserAvatarProfile';
import CloseIcon from '@asset/svg/CloseIcon';
import IconContainer from '@components/common/IconContainer';
import EmptyContent from '@components/common/EmptyContent';
import SearchIcon from '@asset/svg/SearchIcon';
import SearchInput from '@components/common/input/SearchInput';
import { ChatMessageType } from '@asset/enum/chatEnum';
import { StudentsExtras } from '@asset/type/userType';

const CustomGroupChannelListHeader = ({
  selectedChannelCustomType,
  isOpenSearchInput,
  memberSearchInputValue,
  onSelectChannelCustomType,
  toggleSearchInputVisible,
  onSearch,
}: {
  selectedChannelCustomType: GroupChannelCustomType;
  isOpenSearchInput: boolean;
  memberSearchInputValue: string;
  onSelectChannelCustomType: (type: GroupChannelCustomType) => void;
  toggleSearchInputVisible: (value: boolean) => void;
  onSearch: (value: string) => void;
}) => {
  const { setIsOpen } = useChatStore();

  return (
    <div className="h-fit">
      <div className="box-border flex items-center justify-between w-full h-[3.25rem] pt-1 pb-4 pl-5 pr-4 border-b border-solid drag-handle border-my-gray-80">
        <div className="text-body-lg-700">학생 목록</div>
        <div className="flex items-center chat-header-button">
          <IconContainer
            icon={<SearchIcon width="20" height="20" color="var(--gray-10)" />}
            size={'M'}
            onClick={() => toggleSearchInputVisible(!isOpenSearchInput)}
          ></IconContainer>
          <IconContainer
            icon={<CloseIcon width="20" height="20" color="var(--gray-10)" />}
            size={'M'}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
      {isOpenSearchInput && (
        <div className="p-2">
          <SearchInput
            autoFocus
            onEnterKeyDown={(value) => onSearch(value)}
            defaultValue={memberSearchInputValue}
            placeholder="검색"
          />
        </div>
      )}
      <div className="flex items-center justify-start gap-2 px-4 py-2">
        <button
          className={`flex items-center justify-center border-solid rounded-lg ${
            selectedChannelCustomType === GroupChannelCustomType.IN_CLASS
              ? 'text-label-lg-700 text-my-blue-50 border-[2px] border-my-blue-60 py-[calc(0.75rem-1px)] px-[calc(1rem-1px)] bg-my-blue-95'
              : 'text-label-lg-500 text-my-gray-20 border border-my-gray-70 px-4 py-3'
          }`}
          onClick={() =>
            onSelectChannelCustomType(GroupChannelCustomType.IN_CLASS)
          }
        >
          출석
        </button>
        <button
          className={`flex items-center justify-center border-solid rounded-lg ${
            selectedChannelCustomType === GroupChannelCustomType.AT_HOME
              ? 'text-label-lg-700 text-my-blue-50 border-[2px] border-my-blue-60 py-[calc(0.75rem-1px)] px-[calc(1rem-1px)] bg-my-blue-95'
              : 'text-label-lg-500 text-my-gray-20 border border-my-gray-70 px-4 py-3'
          }`}
          onClick={() =>
            onSelectChannelCustomType(GroupChannelCustomType.AT_HOME)
          }
        >
          미출석
        </button>
        <button
          className={`flex items-center justify-center border-solid rounded-lg ${
            !selectedChannelCustomType
              ? 'text-label-lg-700 text-my-blue-50 border-[2px] border-my-blue-60 py-[calc(0.75rem-1px)] px-[calc(1rem-1px)] bg-my-blue-95'
              : 'text-label-lg-500 text-my-gray-20 border border-my-gray-70 px-4 py-3'
          }`}
          onClick={() => onSelectChannelCustomType(null)}
        >
          전체
        </button>
      </div>
    </div>
  );
};

const CustomGroupChannelPreview = ({
  channel,
  studentInfo,
}: {
  channel: GroupChannelType;
  studentInfo: {
    avatarName: string;
    studentName: string;
    recentStudyStatusInfo: {
      elapsedTime: number;
      studentCompletedAt: string;
    };
  };
}) => {
  const { onChannelSelect } = useGroupChannelListContext();
  const { color: statusColor } = getStudentStudyStatus(
    studentInfo?.recentStudyStatusInfo?.studentCompletedAt
      ? DateTime.fromISO(studentInfo.recentStudyStatusInfo.studentCompletedAt)
      : null,
    channel.customType === GroupChannelCustomType.IN_CLASS,
    studentInfo?.recentStudyStatusInfo?.elapsedTime,
    null,
  );
  const isAbnormal = statusColor === 'var(--red-50)';

  return (
    <div
      key={channel.url}
      onClick={() => {
        onChannelSelect(channel);
      }}
      className="flex w-full gap-2 p-4 border-b border-solid cursor-pointer border-my-gray-90"
    >
      <div className="">
        <div className="relative flex w-10 h-10 rounded-[50%]">
          <UserAvatarProfile
            name={studentInfo ? studentInfo.avatarName : ''}
            variant="beam"
          />

          <div className="absolute bottom-[-2px] right-[-2px]">
            <div
              className={`w-2 h-2 border-[2px] border-solid rounded-full border-my-gray-100 box-content`}
              style={{ background: statusColor }}
            ></div>
          </div>
        </div>
      </div>
      <div className="flex-grow min-w-[1px] flex flex-col gap-1 justify-center">
        <div className="text-label-lg-700">
          {studentInfo?.studentName}
          {isAbnormal && (
            <span
              className={`text-my-red-50 text-label-xs-600 bg-my-red-95 py-[0.125rem] px-1 rounded-xl ml-1`}
            >
              학습 지연
            </span>
          )}
        </div>
        <div
          className="text-body-md-500"
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
          }}
        >
          {channel.lastMessage.isFileMessage() ||
          channel.lastMessage.isMultipleFilesMessage()
            ? '사진'
            : channel.lastMessage.customType === ChatMessageType.GOOGLE_MEET
            ? '화상 회의'
            : channel.lastMessage.customType === ChatMessageType.QUESTION
            ? '새로운 질문이 있습니다.'
            : channel.lastMessage.message}
        </div>
      </div>
      <div className="flex flex-col items-end justify-start gap-1 min-w-[4rem]">
        <div className="text-label-sm-500 text-my-gray-50">
          {convertTimeToText(
            DateTime.fromMillis(channel.lastMessage.createdAt),
            'ko',
          )}
        </div>
        {channel.unreadMessageCount > 0 && (
          <div
            className={`py-[0.19rem] px-[0.38rem] w-fit text-my-gray-100 text-label-xs-700 bg-my-orange-50  ${
              channel.unreadMessageCount > 100
                ? 'rounded-[62.4375rem]'
                : channel.unreadMessageCount > 9
                ? 'rounded-[45%]'
                : 'rounded-[50%]'
            }`}
          >
            {channel.unreadMessageCount > 99
              ? `99+`
              : channel.unreadMessageCount}
          </div>
        )}
      </div>
    </div>
  );
};

const CustomGroupChannelList = ({
  selectedChannelCustomType,
  isOpenSearchInput,
  memberSearchInputValue,
  channelUrlsIncludeSearchMember,
  onSelectChannelCustomType,
  toggleSearchInputVisible,
  onSearch,
}: {
  selectedChannelCustomType: GroupChannelCustomType;
  isOpenSearchInput: boolean;
  memberSearchInputValue: string;
  channelUrlsIncludeSearchMember: string[];
  onSelectChannelCustomType: (type: GroupChannelCustomType) => void;
  toggleSearchInputVisible: (value: boolean) => void;
  onSearch: (value: string) => void;
}) => {
  const { clickedChatNotification, setClickedChatNotification } =
    useChatStore();
  const { channelStudentExtraInfoMap, setChannelStudentExtraInfoMap } =
    useGroupChannelListStore();
  const { groupChannels, onChannelSelect } = useGroupChannelListContext();
  const hasNoSearchResults =
    !_.isNil(channelUrlsIncludeSearchMember) &&
    channelUrlsIncludeSearchMember.length === 0;

  const { data: channelStudentsExtraInfoMap } = useQuery(
    ['GET_STUDENTS_EXTRA_INFO', groupChannels, hasNoSearchResults],
    async (): Promise<{
      [channelUrl: string]: StudentsExtras;
    }> => {
      const groupChannelsUrls = [];
      const studentIdChannelMap = new Map<number, GroupChannelType>();
      const notCachedMetaDataChannels: GroupChannelType[] = [];
      _.map(groupChannels, (channel) => {
        groupChannelsUrls.push(channel.url);
        if (!_.isEmpty(channel.cachedMetaData)) {
          studentIdChannelMap.set(
            Number(channel.cachedMetaData['studentId']),
            channel,
          );
        } else {
          notCachedMetaDataChannels.push(channel);
        }
      });
      const hasNewChannelToCheck =
        _.difference(groupChannelsUrls, Object.keys(channelStudentExtraInfoMap))
          .length > 0;

      if (hasNewChannelToCheck) {
        const _channelStudentExtraInfoMap: {
          [channelUrl: string]: StudentsExtras;
        } = {};

        const studentIds: number[] = Array.from(studentIdChannelMap.keys());
        if (notCachedMetaDataChannels.length > 0) {
          await Promise.allSettled(
            _.map(notCachedMetaDataChannels, (channel) => {
              return channel.getAllMetaData();
            }),
          ).then((results) => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                const studentId = Number(result.value.studentId);
                studentIds.push(studentId);
                studentIdChannelMap.set(
                  studentId,
                  notCachedMetaDataChannels[index],
                );
              }
            });
          });
        }

        const studentsExtrasResponse = await getStudentsExtrasApi({
          studentIds,
        });

        _.map(studentsExtrasResponse.data, (student) => {
          _channelStudentExtraInfoMap[
            studentIdChannelMap.get(student.studentId).url
          ] = {
            ...student,
          };
        });
        setChannelStudentExtraInfoMap(_channelStudentExtraInfoMap);
        return _channelStudentExtraInfoMap;
      } else {
        return channelStudentExtraInfoMap;
      }
    },
    {
      enabled: groupChannels.length > 0 && !hasNoSearchResults,
    },
  );

  useEffect(() => {
    if (!_.isEmpty(clickedChatNotification) && groupChannels.length > 0) {
      onChannelSelect(
        groupChannels.find(
          (channel) => channel.url === clickedChatNotification.channelUrl,
        ),
      );
      setClickedChatNotification(null);
    }
  }, [clickedChatNotification?.channelUrl, groupChannels]);

  const channelListHeader = (
    <CustomGroupChannelListHeader
      selectedChannelCustomType={selectedChannelCustomType}
      isOpenSearchInput={isOpenSearchInput}
      memberSearchInputValue={memberSearchInputValue}
      onSelectChannelCustomType={onSelectChannelCustomType}
      toggleSearchInputVisible={toggleSearchInputVisible}
      onSearch={onSearch}
    />
  );

  if (!_.isEmpty(channelStudentsExtraInfoMap) && !hasNoSearchResults) {
    return (
      <GroupChannelListUI
        renderHeader={() => channelListHeader}
        renderChannelPreview={(props) => (
          <CustomGroupChannelPreview
            channel={props.channel}
            studentInfo={channelStudentsExtraInfoMap[props.channel.url]}
          />
        )}
      />
    );
  } else {
    return (
      <section>
        {channelListHeader}
        <div>
          <EmptyContent icon={'list'} text={'목록 없음'} />
        </div>
      </section>
    );
  }
};

export default CustomGroupChannelList;
