import styled from 'styled-components';
import tw from 'twin.macro';

export const SideBarListItem = styled.li`
  & {
    ${tw`flex flex-col items-center gap-1 cursor-pointer`}

    div {
      ${tw`flex items-center justify-center w-8 h-8`}
      svg {
        fill: var(--gray-100);
      }
    }

    span {
      ${tw`text-my-gray-70`}
      font-size: 0.6875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 0.875rem;
      white-space: nowrap;
    }
    &:hover > div,
    &.picked > div {
      background-color: var(--gray-30);
      border-radius: 0.5rem;
    }
  }
`;
export const SideBarListLayout = styled.section`
  & {
    width: 4rem;
    position: sticky;
    top: var(--height-global-nav);
    z-index: 1;

    > div {
      display: flex;
      flex-direction: column;
      width: ${(props) =>
        props.isOpen
          ? 'var(--width-open-side-bar)'
          : 'var(--width-close-side-bar)'};
      height: 100%;
      min-width: ${(props) =>
        props.isOpen
          ? 'var(--width-open-side-bar)'
          : 'var(--width-close-side-bar)'};
      background: var(--gray-20);
      overflow-y: auto;
      transition-duration: 0.3s;

      > ul {
        ${tw`flex flex-col gap-3 p-2`}
      }
    }
  }
`;
