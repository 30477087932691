import React from 'react';

const ScheduleIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-30)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.2 11.133L11.1333 10.1997L8.66665 7.73301V4.66634H7.33331V8.26634L10.2 11.133ZM7.99998 14.6663C7.07776 14.6663 6.21109 14.4913 5.39998 14.1413C4.58887 13.7913 3.88331 13.3163 3.28331 12.7163C2.68331 12.1163 2.20831 11.4108 1.85831 10.5997C1.50831 9.78856 1.33331 8.9219 1.33331 7.99967C1.33331 7.07745 1.50831 6.21079 1.85831 5.39967C2.20831 4.58856 2.68331 3.88301 3.28331 3.28301C3.88331 2.68301 4.58887 2.20801 5.39998 1.85801C6.21109 1.50801 7.07776 1.33301 7.99998 1.33301C8.9222 1.33301 9.78887 1.50801 10.6 1.85801C11.4111 2.20801 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7916 4.58856 14.1416 5.39967C14.4916 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4916 9.78856 14.1416 10.5997C13.7916 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.4111 13.7913 10.6 14.1413C9.78887 14.4913 8.9222 14.6663 7.99998 14.6663ZM7.99998 13.333C9.47776 13.333 10.7361 12.8136 11.775 11.7747C12.8139 10.7358 13.3333 9.47745 13.3333 7.99967C13.3333 6.5219 12.8139 5.26356 11.775 4.22467C10.7361 3.18579 9.47776 2.66634 7.99998 2.66634C6.5222 2.66634 5.26387 3.18579 4.22498 4.22467C3.18609 5.26356 2.66665 6.5219 2.66665 7.99967C2.66665 9.47745 3.18609 10.7358 4.22498 11.7747C5.26387 12.8136 6.5222 13.333 7.99998 13.333Z"
        fill={color}
      />
    </svg>
  );
};

export default ScheduleIcon;
