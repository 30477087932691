import styled from 'styled-components';
import tw from 'twin.macro';

export const ListTableStyle = styled.section`
  & {
    ${tw`w-full h-full`}

    > article {
      ${tw`flex flex-col h-full`}
    }

    input[type='checkbox'] {
      min-width: 1.25rem;
      min-height: 1.25rem;
    }
    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
    section.header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 1rem 0;
      > div {
        flex: 1 0 50%;
      }
      div.search-section {
        display: flex;
        justify-content: space-between;
      }
    }

    .table-wrapper {
      ${tw`flex-grow w-full overflow-x-auto`}
      table {
        min-width: 100%;
        text-align: left;
        box-sizing: border-box;
        word-break: break-word;

        th,
        td {
          padding: 0.5rem;
          vertical-align: middle;
          text-align: left;
        }

        thead {
          ${tw`h-12 text-body-md-700 text-my-gray-30`}
          background-color: var(--gray-95);
          overflow: hidden;
          border-bottom: 1px solid var(--gray-70);

          th {
            p {
              ${tw`text-label-lg-600`}
            }
          }
        }
        tbody {
          ${tw`text-body-md-500 text-my-gray-10 bg-my-gray-100`}

          tr {
            ${tw`h-14 hover:bg-my-gray-95`}
            border-bottom: 1px solid var(--gray-80);

            td {
              padding-right: 1rem;
              padding-left: 1rem;
            }
          }
        }

        tbody.empty-table td {
          height: 3.5rem;
          background-color: var(--old-grey-20);
          text-align: center;
          font-weight: 400;
          font-size: 0.75rem;
          color: var(--old-grey-800);
        }
      }
    }

    @media screen and (max-width: 1200px) {
      table {
        th,
        td {
          padding: 0.5rem;
        }
      }
    }
  }
`;
