import React from 'react';

const EditIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33333 12.6667H4.26667L10.8 6.15L9.85 5.2L3.33333 11.7333V12.6667ZM2.66667 14C2.47778 14 2.31944 13.9361 2.19167 13.8083C2.06389 13.6806 2 13.5222 2 13.3333V11.7167C2 11.5389 2.03333 11.3694 2.1 11.2083C2.16667 11.0472 2.26111 10.9056 2.38333 10.7833L10.8 2.38333C10.9222 2.26111 11.0667 2.16667 11.2333 2.1C11.4 2.03333 11.5722 2 11.75 2C11.9278 2 12.0972 2.03333 12.2583 2.1C12.4194 2.16667 12.5667 2.26667 12.7 2.4L13.6167 3.33333C13.75 3.45556 13.8472 3.59722 13.9083 3.75833C13.9694 3.91944 14 4.08889 14 4.26667C14 4.43333 13.9694 4.6 13.9083 4.76667C13.8472 4.93333 13.75 5.08333 13.6167 5.21667L5.21667 13.6167C5.09444 13.7389 4.95278 13.8333 4.79167 13.9C4.63056 13.9667 4.46111 14 4.28333 14H2.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
