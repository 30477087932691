import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useChatStore } from '@store/chat';
import IconContainer from '@components/common/IconContainer';
import BasicButton from '@components/common/button/BasicButton';
import { DateTimePicker } from '@components/common/DateTimePicker';
import BackIcon from '@asset/svg/BackIcon';
import CloseIcon from '@asset/svg/CloseIcon';
import ScheduledMessageList from './scheduleMessage/ScheduledMessageList';
import { patchScheduledMessage, postScheduledMessage } from '@apis/api/chat';
import AttachImageForm from '@components/common/form/AttachImageForm';
import { toast } from 'react-toastify';
import { urlToFileObject } from '@asset/function/commonFunctions';
import { uploadChatFiles } from '@asset/function/chat';
import { ScheduledMessage } from '@asset/type/chat';
import { useMutation } from 'react-query';

enum ScheduledMessageFormEnum {
  MESSAGE = 'message',
  FILE = 'file',
  SCHEDULED_AT = 'scheduledAt',
}
enum ScheduledMessageSectionView {
  CREATE_MESSAGE = 'create_message',
  MESSAGE_LIST = 'message_list',
}
interface ScheduledMessageHookForm {
  [ScheduledMessageFormEnum.MESSAGE]: string;
  [ScheduledMessageFormEnum.SCHEDULED_AT]: DateTime;
}

const CustomScheduledMessageSection = ({
  toggleScheduledMessageSectionVisible,
}: {
  toggleScheduledMessageSectionVisible: (isVisible: boolean) => void;
}) => {
  const { setIsOpen } = useChatStore();
  const { currentChannel } = useGroupChannelContext();

  const scheduledMessageForm = useForm<ScheduledMessageHookForm>({
    mode: 'onChange',
  });
  const [selectedView, setSelectedView] = useState<ScheduledMessageSectionView>(
    ScheduledMessageSectionView.CREATE_MESSAGE,
  );
  const [updateTargetMessage, setUpdateTargetMessage] =
    useState<ScheduledMessage>(null);
  const [files, setFiles] = useState<File[]>([]);

  const resetForm = () => {
    scheduledMessageForm.setValue(ScheduledMessageFormEnum.MESSAGE, '');
    scheduledMessageForm.setValue(ScheduledMessageFormEnum.SCHEDULED_AT, null);
    setFiles([]);
    setUpdateTargetMessage(null);
  };

  const {
    isLoading: isLoadingRegisterScheduledMessage,
    mutateAsync: registerScheduledMessage,
  } = useMutation(
    ['REGISTER_SCHEDULED_MESSAGE', currentChannel],
    async (formResult: ScheduledMessageHookForm) => {
      let fileUrls = [];
      if (files.length > 0) {
        fileUrls = await uploadChatFiles(files, () =>
          toast.error('일부 사진 업로드에 실패했습니다.'),
        );
      }
      const postScheduledMessageResponse = await postScheduledMessage(
        currentChannel.url,
        {
          ...formResult,
          files: fileUrls,
        },
      );
      if (postScheduledMessageResponse.success) {
        setSelectedView(ScheduledMessageSectionView.MESSAGE_LIST);
        resetForm();
      }
    },
  );

  const {
    isLoading: isLoadingUpdateScheduledMessage,
    mutateAsync: updateScheduledMessage,
  } = useMutation(
    ['UPDATE_SCHEDULED_MESSAGE'],
    async (formResult: ScheduledMessageHookForm) => {
      const _requestBody = {};
      if (
        updateTargetMessage.message !==
        formResult[ScheduledMessageFormEnum.MESSAGE]
      ) {
        _requestBody[ScheduledMessageFormEnum.MESSAGE] =
          formResult[ScheduledMessageFormEnum.MESSAGE];
      }
      let fileUrls = [];
      if (files.length > 0) {
        fileUrls = await uploadChatFiles(files, () =>
          toast.error('일부 사진 업로드에 실패했습니다.'),
        );
        _requestBody[ScheduledMessageFormEnum.FILE] = fileUrls;
      }
      if (
        scheduledMessageForm.getFieldState(
          ScheduledMessageFormEnum.SCHEDULED_AT,
        ).isDirty ||
        updateTargetMessage.scheduledAt !==
          formResult[ScheduledMessageFormEnum.SCHEDULED_AT].toISO()
      ) {
        _requestBody[ScheduledMessageFormEnum.SCHEDULED_AT] =
          formResult[ScheduledMessageFormEnum.SCHEDULED_AT];
      }
      const patchScheduledMessageResponse = await patchScheduledMessage(
        updateTargetMessage.id,
        _requestBody,
      );
      if (patchScheduledMessageResponse.success) {
        setSelectedView(ScheduledMessageSectionView.MESSAGE_LIST);
        resetForm();
      }
    },
  );

  const openUpdateMessageForm = async (message: ScheduledMessage) => {
    setUpdateTargetMessage(message);
    setSelectedView(ScheduledMessageSectionView.CREATE_MESSAGE);
    scheduledMessageForm.setValue(
      ScheduledMessageFormEnum.MESSAGE,
      message.message,
    );
    scheduledMessageForm.setValue(
      ScheduledMessageFormEnum.SCHEDULED_AT,
      DateTime.fromISO(message.scheduledAt),
    );
    const originalFiles = [];
    for (const fileInfo of message.files) {
      const file = await urlToFileObject(fileInfo.url);
      originalFiles.push(file);
    }
    setFiles(originalFiles);
  };

  return (
    <section className="flex flex-col h-full">
      <div className="h-[3.25rem] pr-4 pb-3 pl-5 border-b border-solid drag-handle border-my-gray-80 flex items-center gap-2">
        <IconContainer
          icon={
            <BackIcon width="1.5rem" height="1.5rem" color="var(--gray-10)" />
          }
          size={'M'}
          onClick={() => {
            if (selectedView === ScheduledMessageSectionView.CREATE_MESSAGE) {
              toggleScheduledMessageSectionVisible(false);
            } else {
              setSelectedView(ScheduledMessageSectionView.CREATE_MESSAGE);
            }
          }}
        />
        <div className="items-center flex-grow text-body-lg-700 text-my-gray-10">
          {selectedView === ScheduledMessageSectionView.CREATE_MESSAGE
            ? '메시지 예약'
            : '예약 목록'}
        </div>
        <IconContainer
          icon={
            <CloseIcon width="1.5rem" height="1.5rem" color="var(--gray-10)" />
          }
          size={'M'}
          onClick={() => setIsOpen(false)}
        />
      </div>
      {selectedView === ScheduledMessageSectionView.CREATE_MESSAGE ? (
        <form
          className="flex flex-col flex-grow min-h-[1px]"
          onSubmit={scheduledMessageForm.handleSubmit(async (data) => {
            if (_.isNil(updateTargetMessage)) {
              await registerScheduledMessage(data);
            } else {
              await updateScheduledMessage(data);
            }
          })}
        >
          <FormProvider {...scheduledMessageForm}>
            <div className="flex-grow min-h-[1px] flex flex-col">
              <div className="flex-grow min-h-[1px]">
                <textarea
                  {...scheduledMessageForm.register(
                    ScheduledMessageFormEnum.MESSAGE,
                    { required: true },
                  )}
                  className="w-full h-full p-6 resize-none focus-visible:outline-none placeholder:text-body-lg-500 placeholder:text-my-gray-50"
                  placeholder="예약 메시지를 입력하세요."
                ></textarea>
              </div>
              <div className="flex flex-col w-full gap-4 p-4 border-t border-solid border-my-gray-80 bg-my-gray-100">
                <div className="flex flex-col gap-2">
                  <AttachImageForm
                    isEditMode
                    fileList={files}
                    setFileList={setFiles}
                  ></AttachImageForm>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-label-lg-700 text-my-gray-10">
                    예약 시간
                  </div>
                  <div>
                    <DateTimePicker
                      disabled={{ before: DateTime.now() }}
                      defaultDateTime={
                        scheduledMessageForm.watch(
                          ScheduledMessageFormEnum.SCHEDULED_AT,
                        ) ?? null
                      }
                      onTimeChange={(date) => {
                        scheduledMessageForm.setValue(
                          ScheduledMessageFormEnum.SCHEDULED_AT,
                          date,
                          { shouldDirty: true },
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-3 border-t border-solid border-my-gray-80">
              <div>
                <BasicButton
                  color={'gray'}
                  assetType={'Secondary'}
                  size={'S'}
                  content={'예약 목록'}
                  onClickEvent={() => {
                    setSelectedView(ScheduledMessageSectionView.MESSAGE_LIST);
                  }}
                />
              </div>
              <div className="flex items-center gap-2">
                <BasicButton
                  color={'blue'}
                  assetType={'Secondary'}
                  size={'S'}
                  content={'취소'}
                  onClickEvent={() =>
                    toggleScheduledMessageSectionVisible(false)
                  }
                />
                <BasicButton
                  color={'blue'}
                  assetType={'Primary'}
                  size={'S'}
                  content={'예약'}
                  isActive={
                    !isLoadingRegisterScheduledMessage &&
                    !isLoadingUpdateScheduledMessage &&
                    scheduledMessageForm.formState.isValid &&
                    !_.isNil(
                      scheduledMessageForm.watch(
                        ScheduledMessageFormEnum.SCHEDULED_AT,
                      ),
                    )
                      ? true
                      : false
                  }
                  buttonType="submit"
                />
              </div>
            </div>
          </FormProvider>
        </form>
      ) : (
        <ScheduledMessageList
          onClickScheduledMessage={(scheduledMessage) =>
            openUpdateMessageForm(scheduledMessage)
          }
        />
      )}
    </section>
  );
};

export default CustomScheduledMessageSection;
