import {
  MaterialType,
  StudyContentPageType,
  TotalCourse,
} from '@asset/enum/materialEnum';
import { StudyStep } from '@asset/enum/problemSolvingEnum';

export enum GroupChannelCustomType {
  IN_CLASS = 'IN_CLASS',
  AT_HOME = 'AT_HOME',
}

export interface ChannelMetaData {
  isMainClass: boolean;
  studentId: number;
  studentUserId: string;
  teacherId: number;
  teacherUserId: string;
}

export interface QuestionChatMessage {
  studyContentId: number;
  studyContentType: StudyContentPageType;
  studyStep: StudyStep;
  problemStatusDetailId: number;
  pageId: number;
  reportId: number;
  materialCourse: TotalCourse;
  materialType: MaterialType;
  materialName: string;
  indexNameType: string;
  indexNumber: number;
  division: string;
  subIndexNumber: number;
}

export interface ScheduledMessage {
  id: number;
  message: string;
  scheduledAt: string;
  files: [
    {
      url: string;
    },
  ];
}
