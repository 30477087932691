import {
  getActiveElementBackground,
  getHoveredElementBackground,
} from '@styles/globalsStyles';
import styled from 'styled-components';
import tw from 'twin.macro';

export const IconContainer = styled.div`
  & {
    ${tw`relative rounded-lg`}
    width: ${(props) => (props.size === 'S' ? '1.5rem' : '2rem')};
    height: ${(props) => (props.size === 'S' ? '1.5rem' : '2rem')};

    border: ${(props) => props.border};
    border-radius: ${(props) => props.borderRadius};

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    &:hover:not(:disabled) {
      background: ${(props) =>
        props.darkMode
          ? 'var(--gray-30)'
          : getHoveredElementBackground(props.bgColor)};
    }

    &:active:not(:disabled) {
      background: ${(props) =>
        props.darkMode
          ? 'var(--gray-30)'
          : getActiveElementBackground(props.bgColor)};
    }

    .icon {
      ${tw`absolute top-0 left-0 flex items-center justify-center w-full h-full p-1 rounded-lg`}
    }
  }
`;
