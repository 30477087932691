import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';
import { cn } from '@components/lib/utils';
import ChevronLeft from '@asset/svg/ChevronLeft';
import ChevronRight from '@asset/svg/ChevronRight';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      fixedWeeks
      locale={ko}
      formatters={{
        formatCaption: (date, options) => (
          <div>{format(date, 'yyyy년 MMMM', options)}</div>
        ),
      }}
      showOutsideDays={showOutsideDays}
      className={cn('p-4', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-label-lg-600 text-my-gray-10',
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-my-gray-30 rounded-md w-10 h-10 text-label-md-500 flex items-center justify-center',
        row: 'flex w-full mt-[0.12rem]',
        cell: 'h-10 w-10 text-center text-label-md-500 p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-lg [&:has([aria-selected].day-outside)]:bg-neutral-100/50 [&:has([aria-selected])]:bg-neutral-100 first:[&:has([aria-selected])]:rounded-l-lg last:[&:has([aria-selected])]:rounded-r-lg focus-within:relative focus-within:z-20 dark:[&:has([aria-selected].day-outside)]:bg-neutral-800/50 dark:[&:has([aria-selected])]:bg-neutral-800',
        day: 'rounded-lg hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-neutral-800 dark:hover:text-neutral-50 h-10 w-10 p-0 font-normal aria-selected:opacity-100',
        day_range_end: 'day-range-end',
        day_selected: `bg-my-blue-50 !text-my-gray-100 hover:bg-neutral-900 hover:text-neutral-50 focus:bg-my-blue-50 focus:text-neutral-50`,
        day_today: '!text-label-md-700 text-my-orange-50',
        day_outside:
          'day-outside text-neutral-500 aria-selected:bg-neutral-100/50 aria-selected:text-neutral-500',
        day_disabled: 'text-neutral-500 opacity-50 dark:text-neutral-400',
        day_range_middle:
          'aria-selected:bg-neutral-100 aria-selected:text-neutral-900 dark:aria-selected:bg-neutral-800 dark:aria-selected:text-neutral-50',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <ChevronLeft width="1.5rem" height="1.5rem" color="var(--gray-10)" />
        ),
        IconRight: () => (
          <ChevronRight width="1.5rem" height="1.5rem" color="var(--gray-10)" />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
