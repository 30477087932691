import { ClickAwayListener } from '@mui/material';
import * as S from '@styles/styles';
import React from 'react';
import { Oval } from 'react-loader-spinner';

interface MenuItem {
  id: string | number;
  content: string | JSX.Element;
  selected?: boolean;
}

interface BasicMenuProps {
  menuTitle?: string;
  items: MenuItem[];
  onClickAway: () => void;
  onClickItem?: (selectedItem: MenuItem) => void;
  customStyles?: {
    top?: string;
    right?: string;
    transform?: string;
    left?: string;
  };
  isLoading?: boolean;
}

interface BasicMenuItemContentProps {
  icon: JSX.Element;
  text: string;
  textColor?: string;
  isLoading?: boolean;
}

export const BasicMenuItemContent = ({
  icon,
  text,
  textColor = '',
  isLoading = false,
}: BasicMenuItemContentProps) => {
  return (
    <S.BasicMenuItemContent
      className={` ${textColor ? textColor : 'text-my-gray-10'}`}
    >
      <div>
        {icon}
        <span>{text}</span>
      </div>
      {isLoading && (
        <Oval
          height={16}
          width={16}
          color="var(--blue-50)"
          secondaryColor="var(--gray-80)"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      )}
    </S.BasicMenuItemContent>
  );
};

const BasicMenu = ({
  menuTitle = null,
  items,
  onClickAway,
  onClickItem = null,
  customStyles,
  isLoading = false,
}: BasicMenuProps) => {
  return (
    <ClickAwayListener
      onClickAway={() => onClickAway()}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <S.BasicMenu customStyles={customStyles} isLoading={isLoading}>
        {menuTitle && <span>{menuTitle}</span>}
        {items.map((menu) => (
          <li
            key={menu.id}
            className={menu.selected ? 'selected' : ''}
            onClick={(e) => {
              onClickItem && onClickItem(menu);
              e.stopPropagation();
            }}
          >
            {menu.content}
          </li>
        ))}
      </S.BasicMenu>
    </ClickAwayListener>
  );
};

export default BasicMenu;
