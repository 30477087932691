import Image from 'next/image';
import React from 'react';

const EmptyContent = ({ icon, text, classes = '', style = null }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-4 p-6 rounded-xl ${classes}`}
      style={{
        ...style,
      }}
    >
      <Image src={`/common/icon/empty-${icon}.svg`} width={80} height={80} />
      <p className="text-body-md-500 text-my-gray-50">{text}</p>
    </div>
  );
};

export default EmptyContent;
