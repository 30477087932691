import Image from 'next/image';
import React, { useRef } from 'react';
import styled from 'styled-components';

const AutoHeightImageWrapper = styled.div`
  width: 100%;
  position: relative;
  & > span {
    position: unset !important;
    & .autoImage {
      object-fit: contain !important;
      position: relative !important;
      height: auto !important;
    }
  }
`;

const AutoHeightImage = ({
  src,
  priority = false,
  imgLoadEvent = null,
  onError = null,
}) => {
  const imgRef = useRef(null);

  return (
    <AutoHeightImageWrapper>
      {src && (
        <Image
          unoptimized
          src={src}
          alt="이미지가 없습니다."
          layout={'fill'}
          className="autoImage"
          priority={priority}
          onLoadingComplete={(e) => {
            imgLoadEvent && imgLoadEvent(e);
          }}
          onLoad={(e) => {
            const target = e.target as HTMLImageElement;
            if (target.src.indexOf('data:image/gif;base64') < 0) {
              imgRef.current = e;
            }
          }}
          onError={onError}
        ></Image>
      )}
    </AutoHeightImageWrapper>
  );
};

export default AutoHeightImage;
