import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingScreen = () => {
  return (
    <div className={`absolute top-0 left-0 z-[10000] w-full h-full`}>
      <LoadingSpinner height={'100%'} background="white" />
    </div>
  );
};

export default LoadingScreen;
