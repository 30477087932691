import { useState } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Dialog from '@components/common/modal/Dialog';
import * as S from '@styles/chat.style';
import CancelFillIcon from '@asset/svg/CancelFillIcon';
import { deleteScheduledMessage, getScheduledMessage } from '@apis/api/chat';
import { ScheduledMessage } from '@asset/type/chat';

const ScheduledMessageList = ({
  onClickScheduledMessage,
}: {
  onClickScheduledMessage: (scheduledMessage: ScheduledMessage) => void;
}) => {
  const { currentChannel } = useGroupChannelContext();
  const { data: scheduledMessagesList, refetch: refetchScheduledMessagesList } =
    useQuery<ScheduledMessage[]>(
      ['GET_SCHEDULED_MESSAGES', currentChannel.url],
      async () => {
        return (await getScheduledMessage(currentChannel.url)).data;
      },
      { initialData: [], enabled: !_.isNil(currentChannel) },
    );

  const [targetScheduledMessage, setTargetScheduledMessage] =
    useState<ScheduledMessage>(null);
  const [openCancelScheduledMessageModal, setOpenCancelScheduledMessageModal] =
    useState<boolean>(false);

  return (
    <>
      <div className="flex-grow overflow-y-auto">
        <div className="flex flex-col gap-2 h-fit bg-my-gray-95">
          {scheduledMessagesList.map((scheduledMessage) => (
            <S.ScheduledMessageListItem
              key={scheduledMessage.id}
              onClick={() => {
                onClickScheduledMessage(scheduledMessage);
              }}
            >
              <div className="time">
                <div>
                  {DateTime.fromISO(scheduledMessage.scheduledAt).toFormat(
                    'yyyy. M. d EEEE a h:mm',
                    { locale: 'ko' },
                  )}
                </div>
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    setTargetScheduledMessage(scheduledMessage);
                    setOpenCancelScheduledMessageModal(true);
                  }}
                >
                  <CancelFillIcon
                    width="1rem"
                    height="1rem"
                    color="var(--gray-60)"
                  />
                </button>
              </div>
              <div className="content">{scheduledMessage.message}</div>
            </S.ScheduledMessageListItem>
          ))}
        </div>
      </div>
      {openCancelScheduledMessageModal && (
        <Dialog
          title={'예약 메시지 삭제'}
          content={['예약 메시지를 삭제하시겠습니까?']}
          buttons={[
            {
              color: 'gray',
              assetType: 'Secondary',
              content: '취소',
              onClickEvent: () => setOpenCancelScheduledMessageModal(false),
            },
            {
              color: 'red',
              assetType: 'Primary',
              content: '삭제하기',
              onClickEvent: async () => {
                await deleteScheduledMessage(targetScheduledMessage.id)
                  .then(() => {
                    setOpenCancelScheduledMessageModal(false);
                    refetchScheduledMessagesList();
                    toast.success('예약 메시지가 삭제되었습니다.');
                  })
                  .catch(() => {
                    setOpenCancelScheduledMessageModal(false);
                    toast.error('예약 메시지 삭제에 실패했습니다.');
                  });
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ScheduledMessageList;
