import React, { useState } from 'react';
import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import { StudentInformation } from '@apis/api/dashboard';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { RenderMessageParamsType } from '@sendbird/uikit-react/types/types';
import { Message } from '@sendbird/uikit-react/GroupChannel/components/Message';
import MessageContent, {
  MessageBody,
  MessageProfile,
} from '@sendbird/uikit-react/ui/MessageContent';
import * as S from '@styles/chat.style';
import MessageEmojiMenu, {
  MessageEmojiMenuProps,
} from '@sendbird/uikit-react/ui/MessageItemReactionMenu';
import EmojiReactions, {
  EmojiReactionsProps,
} from '@sendbird/uikit-react/ui/EmojiReactions';
import { MessageBodyProps } from '@sendbird/uikit-react/types/ui/MessageContent/MessageBody';
import GoogleMeetIcon from '@asset/svg/GoogleMeetIcon';
import { ChatMessageType } from '@asset/enum/chatEnum';
import CustomMessageMenuButton from './CustomMessageMenuButton';
import { MessageProfileProps } from '@sendbird/uikit-react/types/ui/MessageContent/MessageProfile';
import { PhotoSlider } from 'react-photo-view';
import { QuestionChatMessage } from '@asset/type/chat';
import BasicButton from '@components/common/button/BasicButton';
import { getManageQuestionDetailPageUrlObject } from 'routes/common';
import qs from 'qs';
import { getProblemSolvingUrlObject } from 'routes/studyContent';
import { getUsernameFromNickname } from '@asset/function/chat';
import { useChatStore } from '@store/chat';
import Image from 'next/image';
import AutoHeightImage from '@components/common/AutoHeightImage';
import _ from 'lodash';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';

const CustomMessage = ({
  messageProps,
  studentInfo,
}: {
  messageProps: RenderMessageParamsType;
  studentInfo: StudentInformation;
}) => {
  const { data: user } = useUserInfoQuery();
  const { currentChannel } = useGroupChannelContext();
  const { config } = useSendbirdStateContext();
  const unreadMessageCount = currentChannel.getUnreadMemberCount(
    messageProps.message,
  );
  const hasReactions = messageProps.message.reactions.length > 0;
  const isReplyingMessage = !_.isNil(messageProps.message.parentMessage);
  const isMessageByMe =
    messageProps.message.isUserMessage() ||
    messageProps.message.isFileMessage() ||
    messageProps.message.isMultipleFilesMessage()
      ? messageProps.message.sender.userId === config.userId
      : null;
  const repliedString = isReplyingMessage
    ? messageProps.message.parentMessage.isUserMessage() ||
      messageProps.message.parentMessage.isFileMessage() ||
      messageProps.message.parentMessage.isMultipleFilesMessage()
      ? user?.type === UserType.STUDENT
        ? getUsernameFromNickname(
            messageProps.message.parentMessage.sender.nickname,
          ) === studentInfo.name
          ? '나'
          : '선생님'
        : `${getUsernameFromNickname(
            messageProps.message.parentMessage.sender.nickname,
          )}`
      : '누군가'
    : '누군가';

  const parentMessagePhotoThumbnail = messageProps.message.parentMessage
    ? messageProps.message.parentMessage.isFileMessage()
      ? messageProps.message.parentMessage.plainUrl
      : null
    : null;

  return (
    <S.ChatMessage
      userType={user.type}
      unreadMessageCount={unreadMessageCount}
      hasReactions={hasReactions}
      repliedString={repliedString}
      parentMessagePhotoThumbnail={parentMessagePhotoThumbnail}
    >
      <Message
        {...messageProps}
        renderMessageContent={(props) => {
          return (
            <>
              {isReplyingMessage && isMessageByMe === false && (
                <S.ChatSender>
                  {messageProps.message.isUserMessage() ||
                  messageProps.message.isFileMessage() ||
                  messageProps.message.isMultipleFilesMessage()
                    ? `${getUsernameFromNickname(
                        messageProps.message.sender.nickname,
                      )}${
                        messageProps.message.sender.nickname.startsWith('[T]')
                          ? ' 선생님'
                          : ''
                      }`
                    : ''}
                </S.ChatSender>
              )}
              <MessageContent
                {...props}
                renderSenderProfile={(props: MessageProfileProps) => (
                  <S.ChatMessageProfile>
                    <MessageProfile {...props} />
                  </S.ChatMessageProfile>
                )}
                renderMessageMenu={(menuProps) => (
                  <CustomMessageMenuButton menuProps={menuProps} />
                )}
                renderEmojiMenu={(props: MessageEmojiMenuProps) => (
                  <S.ChatMessageEmojiMenuButton>
                    <MessageEmojiMenu {...props} />
                  </S.ChatMessageEmojiMenuButton>
                )}
                renderMessageHeader={(props) => (
                  <S.ChatSender>
                    {user
                      ? user.type === UserType.TEACHER
                        ? props.message.isUserMessage() ||
                          props.message.isFileMessage()
                          ? getUsernameFromNickname(
                              props.message.sender.nickname,
                            )
                          : ''
                        : studentInfo?.teacherName + ' 선생님'
                      : ''}
                  </S.ChatSender>
                )}
                renderMessageBody={(props: MessageBodyProps) => {
                  const messageCustomType = messageProps.message.customType;
                  return (
                    <>
                      {messageCustomType === ChatMessageType.GOOGLE_MEET ? (
                        <GoogleMeetMessage messageProps={messageProps} />
                      ) : messageCustomType === ChatMessageType.QUESTION ? (
                        <QuestionMessage
                          messageProps={messageProps}
                          studentInfo={studentInfo}
                        />
                      ) : messageProps.message.isFileMessage() ||
                        messageProps.message.isMultipleFilesMessage() ? (
                        <PhotoMessage messageProps={messageProps} />
                      ) : (
                        <MessageBody {...props} />
                      )}
                    </>
                  );
                }}
                renderEmojiReactions={(props: EmojiReactionsProps) => (
                  <S.ChatMessageEmojiReactionContainer userType={user.type}>
                    <EmojiReactions {...props} />
                  </S.ChatMessageEmojiReactionContainer>
                )}
              ></MessageContent>
            </>
          );
        }}
      ></Message>
    </S.ChatMessage>
  );
};

export default CustomMessage;

const PhotoMessage = ({
  messageProps,
}: {
  messageProps: RenderMessageParamsType;
}) => {
  const [visible, setVisible] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const imageURLs = messageProps.message.isFileMessage()
    ? [messageProps.message.url]
    : messageProps.message.isMultipleFilesMessage()
    ? messageProps.message.fileInfoList.map((fileInfo) => fileInfo.url)
    : [];
  const fileCount = imageURLs.length;

  return (
    <>
      {imageURLs.length === 1 ? (
        <div
          className="relative max-[18rem] w-[18rem] rounded-lg overflow-hidden cursor-pointer"
          onPointerDown={() => {
            setPhotoIndex(0);
            setVisible(true);
          }}
        >
          <AutoHeightImage src={imageURLs[0]} priority></AutoHeightImage>
        </div>
      ) : (
        <S.ChatMultiplePhotoMessage
          multipleFilesGridCount={
            fileCount === 1 ? 1 : fileCount === 2 || fileCount === 4 ? 2 : 3
          }
        >
          {imageURLs.map((imageURL, index) => (
            <div
              className="image-wrapper"
              key={imageURL}
              onPointerDown={() => {
                setPhotoIndex(index);
                setVisible(true);
              }}
            >
              <Image
                src={imageURL}
                objectFit="cover"
                priority
                layout="fill"
              ></Image>
            </div>
          ))}
        </S.ChatMultiplePhotoMessage>
      )}
      <PhotoSlider
        maskOpacity={0.8}
        images={imageURLs.map((item) => ({ src: item, key: item }))}
        visible={visible}
        onClose={() => setVisible(false)}
        index={photoIndex}
        onIndexChange={setPhotoIndex}
      ></PhotoSlider>
    </>
  );
};

const GoogleMeetMessage = ({
  messageProps,
}: {
  messageProps: RenderMessageParamsType;
}) => {
  return (
    <S.ChatGoogleMeetMessageBody
      onPointerUp={() => {
        window.open(messageProps.message.message, '_blank');
      }}
    >
      <div>
        <div>화상 회의</div>
        <div>Google Meet</div>
      </div>
      <div>
        <GoogleMeetIcon />
        참여하기
      </div>
    </S.ChatGoogleMeetMessageBody>
  );
};

const QuestionMessage = ({
  messageProps,
  studentInfo,
}: {
  messageProps: RenderMessageParamsType;
  studentInfo: StudentInformation;
}) => {
  const { data: user } = useUserInfoQuery();
  const { setQuestionDetailUrl } = useChatStore();
  const questionProblem: QuestionChatMessage = JSON.parse(
    messageProps.message.data,
  );
  const questionProblemInfoText = `${questionProblem.materialCourse} | ${questionProblem.materialType} | ${questionProblem.materialName} | ${questionProblem.indexNameType} ${questionProblem.indexNumber} ${questionProblem.division} ${questionProblem.subIndexNumber}번`;
  return (
    <div className="flex flex-col w-full gap-4 p-3 bg-my-gray-95 rounded-xl">
      <div className="flex flex-col gap-2">
        <div>
          <div className={`text-title-sm-700 text-my-gray-10`}>
            새로운 질문이 있습니다.
          </div>
          <div></div>
        </div>
        <div className="text-body-md-500 text-my-gray-10">
          {questionProblemInfoText}
        </div>
      </div>
      <BasicButton
        color={'gray'}
        assetType={'Tertiary'}
        size={'S'}
        content={'문제 보기'}
        width="100%"
        onPointerDown={() => {
          if (user.type === UserType.TEACHER) {
            const { pathname, query } = getManageQuestionDetailPageUrlObject({
              problemStatusDetailId: questionProblem.problemStatusDetailId,
              pageId: questionProblem.pageId,
              reportId: questionProblem.reportId,
              teacherIds: [studentInfo.teacherId],
            });
            window.open(
              `${pathname}?${qs.stringify(query, {
                encodeValuesOnly: true,
                arrayFormat: 'comma',
                skipNulls: true,
              })}`,
              '_blank',
            );
          } else {
            const { pathname, query } = getProblemSolvingUrlObject(
              questionProblem.studyContentId,
              questionProblem.studyContentType,
              questionProblem.pageId,
              {
                type: questionProblem.studyStep,
                previousPage: 'my-questions',
                reportId: questionProblem.reportId,
              },
            );
            setQuestionDetailUrl(
              `${pathname}?${qs.stringify(query, {
                encodeValuesOnly: true,
                arrayFormat: 'comma',
                skipNulls: true,
              })}`,
            );
          }
        }}
      />
    </div>
  );
};
