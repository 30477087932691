import _ from 'lodash';
import {
  getGroupChannelsUrlContainsStudentName,
  getSendbirdUserInfo,
} from '@apis/api/chat';
import { useChatStore } from '@store/chat';
import { useUserInfoQuery } from '@apis/query/user';
import { useQuery } from 'react-query';
import {
  GroupChannel as GroupChannelType,
  HiddenChannelFilter,
} from '@sendbird/chat/groupChannel';
import { GroupChannelListProvider } from '@sendbird/uikit-react/GroupChannelList/context';
import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { UserType } from '@asset/enum/UserEnum';
import CustomGroupChannelList from './CustomGroupChannelList';
import { DraggableCore } from 'react-draggable';
import { GroupChannelCustomType } from '@asset/type/chat';
import CustomGroupChatRoom from './CustomGroupChatRoom';
import * as S from '@styles/chat.style';
import LoadingSpinner from '@components/common/loading/LoadingSpinner';
import { CHAT_ROOM_Z_INDEX, customStringSet } from '@asset/enum/chatEnum';

const SendbirdProvider = dynamic(
  () => import('@sendbird/uikit-react/SendbirdProvider'),
  {
    ssr: false,
  },
);

function SendbirdWrapper() {
  const SEND_BIRD_APP_ID = process.env.NEXT_PUBLIC_SEND_BIRD_APP_ID;
  const { data: user } = useUserInfoQuery();
  const {
    isOpen,
    selectedChannelUrl,
    unreadCount,
    clickedChatNotification,
    setIsOpen,
    setSelectedChannelUrl,
    setUnreadCount,
  } = useChatStore();

  const [selectedChannelCustomType, setSelectedChannelCustomType] =
    useState<GroupChannelCustomType>(GroupChannelCustomType.IN_CLASS);
  const [channelUrlsIncludeSearchMember, setChannelUrlsIncludeSearchMember] =
    useState<string[]>(null);
  const [isOpenMemberSearchInput, setIsOpenMemberSearchInput] = useState(false);
  const [memberSearchInputValue, setMemberSearchInputValue] = useState(null);

  const { data: sendbirdUserInfo } = useQuery(
    ['GET_SENDBIRD_USER_INFO', user?.userId],
    () => getSendbirdUserInfo(user?.userId),
    {
      enabled: !_.isNil(user?.userId),
    },
  );
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;

  const updateUnreadCount = (channel: GroupChannelType) => {
    if (!_.isNil(channel)) {
      const _totalUnreadCount =
        unreadCount < channel.unreadMessageCount
          ? 0
          : unreadCount - channel.unreadMessageCount;
      setUnreadCount(_totalUnreadCount);
    }
  };

  useEffect(() => {
    if (sendbirdUserInfo && isOpen) {
      setSelectedChannelUrl(sendbirdUserInfo.currentClassChannelUrl);
    }
  }, [sendbirdUserInfo, isOpen]);

  const closeMemberSearchInput = () => {
    setIsOpenMemberSearchInput(null);
    setMemberSearchInputValue(null);
    setChannelUrlsIncludeSearchMember(null);
  };

  useEffect(() => {
    if (_.isNil(user)) {
      setIsOpen(false);
      setSelectedChannelUrl(null);
      setSelectedChannelCustomType(null);
      closeMemberSearchInput();
    }
  }, [user]);

  const chatRoomRef = useRef(null);
  const chatRoomRemWidth = 30;

  const handleDragChatRoom = (e, data) => {
    // window 가장 윗쪽에서 chatRoomRef까지의 거리
    const chatRoomTopInWindow = chatRoomRef.current.offsetTop;
    // window 가장 왼쪽에서 마우스까지의 거리
    const mouseTopInWindow = data.y;
    // chatRoom 가장 왼쪽에서 마우스까지의 거리
    const mouseTopInChatRoom = mouseTopInWindow - chatRoomTopInWindow;
    const movedTop = mouseTopInWindow - mouseTopInChatRoom + data.deltaY;
    const chatRoomFinalTopInWindow =
      movedTop > 0
        ? movedTop > window.innerHeight - chatRoomRef.current.clientHeight
          ? window.innerHeight - chatRoomRef.current.clientHeight
          : movedTop
        : 0;

    // window 가장 왼쪽에서 chatRoomRef까지의 거리
    const chatRoomLeftInWindow = chatRoomRef.current.offsetLeft;
    // window 가장 왼쪽에서 마우스까지의 거리
    const mouseLeftInWindow = data.x;
    // chatRoom 가장 왼쪽에서 마우스까지의 거리
    const mouseLeftInChatRoom = mouseLeftInWindow - chatRoomLeftInWindow;
    const movedLeft = mouseLeftInWindow - mouseLeftInChatRoom + data.deltaX;
    const chatRoomFinalLeftInWindow =
      movedLeft > 0
        ? movedLeft > window.innerWidth - chatRoomRef.current.clientWidth
          ? window.innerWidth - chatRoomRef.current.clientWidth
          : movedLeft
        : 0;

    chatRoomRef.current.style.top = `${chatRoomFinalTopInWindow}px`;
    chatRoomRef.current.style.left = `${chatRoomFinalLeftInWindow}px`;
  };
  if (!_.isNil(sendbirdUserId) && !_.isNil(sendbirdAccessToken) && isOpen) {
    return (
      <DraggableCore
        cancel=".chat-header-button, .chat-announcement"
        handle=".drag-handle"
        onDrag={handleDragChatRoom}
      >
        <article
          className="min-h-[25rem] fixed flex flex-col bg-my-gray-100 rounded-xl overflow-hidden border border-solid border-my-gray-70"
          style={{
            minWidth: `${chatRoomRemWidth}rem`,
            width: `${chatRoomRemWidth}rem`,
            height: 'calc(100dvh - var(--height-global-nav) - 22rem)',
            top: 'calc(var(--height-global-nav) + 1rem)',
            left: `calc(100% - ${chatRoomRemWidth}rem - 1rem)`,
            boxShadow: '0px 8px 24px 0px rgba(23, 32, 42, 0.16)',
            zIndex: CHAT_ROOM_Z_INDEX,
          }}
          ref={chatRoomRef}
        >
          <div className="flex items-center justify-center pt-2 cursor-pointer drag-handle">
            <div
              className={`w-8 h-1 bg-my-gray-70 rounded-[0.25rem] cursor-pointer`}
            ></div>
          </div>
          <S.ChatGlobalStyle />
          <SendbirdProvider
            appId={SEND_BIRD_APP_ID}
            userId={sendbirdUserId}
            accessToken={sendbirdAccessToken}
            stringSet={customStringSet}
            colorSet={{
              '--sendbird-light-primary-100': 'var(--blue-98)',
              '--sendbird-light-primary-300': 'var(--blue-50)',
              '--sendbird-light-primary-400': 'var(--blue-40)',
              '--sendbird-light-primary-500': 'var(--blue-30)',
              '--sendbird-light-secondary-300': 'var(--blue-50)',
            }}
            isMultipleFilesMessageEnabled
          >
            {_.isNil(selectedChannelUrl) ? (
              user.type === UserType.TEACHER ? (
                <section className="flex w-full h-full">
                  <GroupChannelListProvider
                    onChannelSelect={async (channel: GroupChannelType) => {
                      setSelectedChannelUrl(channel.url);
                      updateUnreadCount(channel);
                    }}
                    onChannelCreated={() => null}
                    disableAutoSelect
                    channelListQueryParams={{
                      hiddenChannelFilter: HiddenChannelFilter.UNHIDDEN,
                      customTypesFilter: !clickedChatNotification
                        ? selectedChannelCustomType
                          ? [selectedChannelCustomType]
                          : null
                        : null,
                      channelUrlsFilter: channelUrlsIncludeSearchMember,
                    }}
                  >
                    <CustomGroupChannelList
                      selectedChannelCustomType={selectedChannelCustomType}
                      isOpenSearchInput={isOpenMemberSearchInput}
                      memberSearchInputValue={memberSearchInputValue}
                      channelUrlsIncludeSearchMember={
                        channelUrlsIncludeSearchMember
                      }
                      onSelectChannelCustomType={setSelectedChannelCustomType}
                      toggleSearchInputVisible={(visible: boolean) => {
                        setIsOpenMemberSearchInput(visible);
                        if (!visible) {
                          closeMemberSearchInput();
                        }
                      }}
                      onSearch={async (value) => {
                        setMemberSearchInputValue(value);
                        const pickedChannelUrls = (
                          await getGroupChannelsUrlContainsStudentName(value)
                        ).data.map((channel) => channel.channelUrl);
                        setChannelUrlsIncludeSearchMember(pickedChannelUrls);
                      }}
                    />
                  </GroupChannelListProvider>
                </section>
              ) : (
                <div>
                  <LoadingSpinner />
                </div>
              )
            ) : (
              <CustomGroupChatRoom
                onCloseGroupChannel={() => setSelectedChannelUrl(null)}
                updateUnreadCount={updateUnreadCount}
              />
            )}
          </SendbirdProvider>
        </article>
      </DraggableCore>
    );
  } else {
    <></>;
  }
}

export default SendbirdWrapper;
