import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import CustomScheduledMessageSection from './CustomScheduledMessageSection';
import CustomChannelHeader from './CustomChannelHeader';
import * as S from '@styles/chat.style';
import { useEffect, useState } from 'react';
import { GroupChannelHeaderProps } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { DateTime } from 'luxon';
import {
  RenderCustomSeparatorProps,
  RenderMessageParamsType,
} from '@sendbird/uikit-react/types/types';
import CustomMessage from './CustomMessage';
import CustomMessageInput from './CustomMessageInput';
import {
  GroupChannelProvider,
  useGroupChannelContext,
} from '@sendbird/uikit-react/GroupChannel/context';
import { useUserInfoQuery } from '@apis/query/user';
import { useQuery } from 'react-query';
import { getStudentInfo } from '@apis/api/dashboard';
import _ from 'lodash';
import { ChannelMetaData } from '@asset/type/chat';
import { addTypeChannelMetaData } from '@asset/function/chat';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useChatStore } from '@store/chat';

const GroupChatRoom = ({
  onCloseGroupChannel,
  updateUnreadCount,
}: {
  onCloseGroupChannel: () => void;
  updateUnreadCount: (channel: GroupChannelType) => void;
}) => {
  const { data: user } = useUserInfoQuery();
  const { selectedChannelUrl } = useChatStore();
  const { currentChannel } = useGroupChannelContext();
  const [isOpenScheduledMessageSection, setIsOpenScheduledMessageSection] =
    useState(false);

  const { data: selectedChannelMetaData } = useQuery(
    ['GET_CHANNEL_META_DATA', currentChannel?.url],
    async (): Promise<ChannelMetaData> => {
      return await currentChannel
        .getAllMetaData()
        .then((response) => {
          return addTypeChannelMetaData(response);
        })
        .catch(() => null);
    },
    { enabled: !!currentChannel },
  );

  const { data: studentInfo } = useQuery(
    ['GET_STUDENT_INFO', selectedChannelMetaData],
    async () => await getStudentInfo(Number(selectedChannelMetaData.studentId)),
    { enabled: !_.isNil(selectedChannelMetaData?.studentId) },
  );

  useEffect(() => {
    if (currentChannel) {
      updateUnreadCount(currentChannel);
    }
  }, [currentChannel?.unreadMessageCount]);

  if (!_.isNil(studentInfo)) {
    return (
      <>
        <div className="w-full h-full">
          {isOpenScheduledMessageSection ? (
            <CustomScheduledMessageSection
              toggleScheduledMessageSectionVisible={(isOpen) =>
                setIsOpenScheduledMessageSection(isOpen)
              }
            />
          ) : (
            <GroupChannel
              channelUrl={selectedChannelUrl}
              renderChannelHeader={(props: GroupChannelHeaderProps) => (
                <CustomChannelHeader
                  GroupChannelHeaderProps={props}
                  studentInfo={studentInfo}
                  onCloseGroupChannel={onCloseGroupChannel}
                />
              )}
              renderCustomSeparator={(props: RenderCustomSeparatorProps) => (
                <S.ChatSeparator>
                  {DateTime.fromMillis(props.message.createdAt).toFormat(
                    'yyyy년 MM월 dd일 cccc',
                    {
                      locale: user.language ?? 'ko',
                    },
                  )}
                </S.ChatSeparator>
              )}
              renderMessage={(props: RenderMessageParamsType) => (
                <CustomMessage messageProps={props} studentInfo={studentInfo} />
              )}
              renderMessageInput={() => (
                <GoogleOAuthProvider
                  clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <CustomMessageInput
                    toggleScheduledMessageSectionVisible={(isOpen) =>
                      setIsOpenScheduledMessageSection(isOpen)
                    }
                  />
                </GoogleOAuthProvider>
              )}
            />
          )}
        </div>
      </>
    );
  }
};

const CustomGroupChatRoom = ({
  onCloseGroupChannel,
  updateUnreadCount,
}: {
  onCloseGroupChannel: () => void;
  updateUnreadCount: (channel: GroupChannelType) => void;
}) => {
  const { selectedChannelUrl } = useChatStore();
  return (
    <section className="flex min-h-[1px] flex-grow w-full">
      <GroupChannelProvider channelUrl={selectedChannelUrl}>
        <GroupChatRoom
          onCloseGroupChannel={onCloseGroupChannel}
          updateUnreadCount={updateUnreadCount}
        ></GroupChatRoom>
      </GroupChannelProvider>
    </section>
  );
};

export default CustomGroupChatRoom;
