import React from 'react';

const ChatIcon = ({
  width = '1rem',
  height = '1rem',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 5.34315 4.34315 4 6 4H18C19.6569 4 21 5.34315 21 7V15C21 16.6569 19.6569 18 18 18H16C15.6852 18 15.3889 18.1482 15.2 18.4L13.6 20.5333C12.8 21.6 11.2 21.6 10.4 20.5333L8.8 18.4C8.61115 18.1482 8.31476 18 8 18H6C4.34315 18 3 16.6569 3 15V7ZM6 6C5.44772 6 5 6.44772 5 7V15C5 15.5523 5.44772 16 6 16H8C8.94427 16 9.83344 16.4446 10.4 17.2L12 19.3333L13.6 17.2C14.1666 16.4446 15.0557 16 16 16H18C18.5523 16 19 15.5523 19 15V7C19 6.44772 18.5523 6 18 6H6Z"
        fill={color}
      />
    </svg>
  );
};

export default ChatIcon;

export const ChatNotificationIcon = ({
  width = '1rem',
  height = '1rem',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="19" cy="5" r="3" fill="#F64857" />
      <path
        d="M14.1 4H6C4.34315 4 3 5.34315 3 7V15C3 16.6569 4.34315 18 6 18H8C8.31476 18 8.61115 18.1482 8.8 18.4L10.4 20.5333C11.2 21.6 12.8 21.6 13.6 20.5333L15.2 18.4C15.3889 18.1482 15.6852 18 16 18H18C19.6569 18 21 16.6569 21 15V9.58396C20.3875 9.85155 19.7111 10 19 10V15C19 15.5523 18.5523 16 18 16H16C15.0557 16 14.1666 16.4446 13.6 17.2L12 19.3333L10.4 17.2C9.83344 16.4446 8.94427 16 8 16H6C5.44772 16 5 15.5523 5 15V7C5 6.44772 5.44772 6 6 6H14.1C14.0344 5.67689 14 5.34247 14 5C14 4.65753 14.0344 4.32311 14.1 4Z"
        fill={color}
      />
    </svg>
  );
};
