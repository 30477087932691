import styled from 'styled-components';
import tw from 'twin.macro';

export const ModalHeaderStyle = styled.div`
  & {
    ${tw`flex items-center justify-between px-3 py-4 border-b border-solid text-title-md-700 border-b-my-gray-80`}
    div.title {
      ${tw`flex items-center gap-3`}
      >div {
        ${tw`w-3 min-h-[1.5rem] h-full rounded bg-my-blue-80`}
      }
    }
  }
`;
export const ModalBodyStyle = styled.div`
  & {
    ${tw`flex flex-wrap items-end`}
    padding: 1.5rem;
    width: ${(props) => (props.bodyWidth ? props.bodyWidth : '31.25rem')};
    max-height: ${(props) => (props.bodyHeight ? props.bodyHeight : '')};
    overflow: ${(props) => (props.bodyHeight ? 'auto' : '')};
    background-color: ${(props) => (props.bgColor ? props.bgColor : '')};
    gap: 1.5rem;
  }
`;
export const ModalFooterStyle = styled.div`
  & {
    ${tw`flex px-4 py-2 border-t border-solid border-t-my-gray-80`}
    gap: 0.5rem;
    justify-content: ${(props) => props.justify ?? 'space-between'};
  }
`;

export const ModalFooterButtonBoxStyle = styled.div`
  & {
    ${tw`flex items-center flex-grow`}
    gap: 0.5rem;
    justify-content: ${(props) => props.justify ?? 'flex-end'};
  }
`;

export const NoContentModalBodyStyle = styled.div`
  & {
    ${tw`flex flex-col items-center px-10 pt-12 text-body-md-500 text-my-gray-20 pb-7`}

    div:first-child {
      ${tw`mb-1 text-title-lg-700 text-my-gray-10`}
    }
  }
`;

export const ReStudyModalBodyStyle = styled.div`
  & {
    ${tw`flex flex-col items-start gap-4 p-10 text-old-my-grey-600`}
    span {
      ${tw`font-semibold text-old-my-grey-800`}
    }
  }
`;

export const DialogModalStyle = styled.div`
  & {
    > div.modal-content {
      width: calc(100% - 5rem);
      min-width: 18rem;
      max-width: 25rem;
      > div.body {
        ${tw`flex flex-col items-center justify-center gap-2 pb-6`}
        > div:first-child {
          ${tw`text-title-lg-700`}
        }
        > div:nth-child(2) {
          ${tw`flex flex-col items-center justify-center text-body-md-500`}
        }
      }
      > div.modal-footer {
        ${tw`flex justify-center gap-4 text-center`}
        >div {
          ${tw`flex-grow w-[45%] flex`}
          > button {
            flex-grow: 1;
          }
        }
      }
    }
  }
`;
