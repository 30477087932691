import tw from 'twin.macro';
import styled from 'styled-components';
import { getHoveredElementBackground } from '@styles/globalsStyles';

const roundedImageWrapper = `
  w-full rounded-lg h-full
`;

export const AttachImageFormLayout = styled.section`
  & {
    ${tw`flex flex-col gap-2`}

    > .title {
      ${tw`text-label-lg-700 text-my-gray-10`}
    }
  }
`;

export const ImageList = styled.div`
  & {
    ${tw`grid grid-cols-6 gap-2`}

    input#add-image {
      ${tw`hidden`}
    }
    label.add-image-box {
      ${tw`${roundedImageWrapper}`}
      ${tw`w-full pt-[100%] relative`}
      ${tw`border border-solid cursor-pointer border-my-gray-70 text-label-sm-600 text-my-gray-50`}
      &:hover {
        background: ${getHoveredElementBackground('transparent')};
      }
      > div {
        ${tw`absolute top-0 flex flex-col items-center justify-center w-full h-full gap-1`}
      }
    }
    img {
      ${tw`${roundedImageWrapper}`}
    }

    .before-attached-image {
      ${tw`relative`}

      > div.delete-button {
        ${tw`absolute`}
        left: 78%;
        top: -10%;
      }
    }

    .image-wrapper {
      ${tw`relative w-full pt-[100%] overflow-hidden`}
    }
  }
`;
