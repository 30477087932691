import React from 'react';

const SendIcon = ({
  width = '1rem',
  height = '1rem',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62199 3.36688C4.63282 3.37167 4.64364 3.37645 4.65445 3.38123L20.5783 10.4193C20.7944 10.5147 21.0135 10.6116 21.185 10.7063C21.3419 10.793 21.6607 10.9803 21.8439 11.3443C22.0519 11.7575 22.0519 12.2441 21.8439 12.6574C21.6607 13.0213 21.3419 13.2086 21.185 13.2953C21.0135 13.3901 20.7944 13.4869 20.5783 13.5823L4.62748 20.6324C4.40555 20.7305 4.18229 20.8292 3.99363 20.8934C3.82084 20.9522 3.46702 21.0621 3.0742 20.9544C2.63249 20.8333 2.26635 20.5127 2.09415 20.0791C1.93995 19.6909 2.01975 19.3203 2.0623 19.1447C2.10901 18.952 2.18735 18.7223 2.26436 18.4965L4.46655 12.0355L2.26898 5.53246C2.26515 5.52112 2.26131 5.50977 2.25747 5.4984C2.18129 5.27313 2.10375 5.0438 2.05764 4.85132C2.01556 4.67567 1.93706 4.3057 2.09167 3.91852C2.26446 3.48581 2.63054 3.16603 3.07182 3.04543C3.464 2.93826 3.81701 3.04774 3.98976 3.10651C4.17806 3.17057 4.40079 3.26907 4.62199 3.36688ZM4.34598 5.43153L6.22801 11.0008H10.7509C11.3032 11.0008 11.7509 11.4485 11.7509 12.0008C11.7509 12.5531 11.3032 13.0008 10.7509 13.0008H6.25051L4.35337 18.5669L19.2092 12.0008L4.34598 5.43153Z"
        fill={color}
      />
    </svg>
  );
};

export default SendIcon;
