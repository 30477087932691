import * as React from 'react';
import { format } from 'date-fns';
import { cn } from '@components/lib/utils';
import { Calendar } from '@components/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@components/components/ui/popover';
import { ScrollArea, ScrollBar } from '@components/components/ui/scroll-area';
import CalendarIcon from '@asset/svg/CalendarIcon';
import { ko } from 'date-fns/locale';
import { DateTime } from 'luxon';
import { useState } from 'react';

export function DateTimePicker({
  defaultDateTime = null,
  disabled = null,
  onTimeChange,
}: {
  defaultDateTime?: DateTime;
  disabled?: { before?: DateTime; after?: DateTime };
  onTimeChange?: (date: DateTime) => void;
}) {
  const [date, setDate] = useState<DateTime>(defaultDateTime ?? null);
  const [isOpen, setIsOpen] = useState(false);

  const isActiveStartDate = (date: DateTime): boolean => {
    if (!disabled || !disabled.before) return false;
    return date.hasSame(disabled.before, 'day');
  };

  const isBeforeActiveStartTime = (hour: number, minute: number) => {
    const targetTime = DateTime.local().set({
      hour: hour,
      minute: minute,
      second: 0,
      millisecond: 0,
    });
    const disabledHour = disabled.before.set({
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return {
      isHourBefore: targetTime < disabledHour,
      isHourMinBefore: targetTime < disabled.before,
    };
  };

  const setUpNewDateTime = (newDateTime: DateTime) => {
    setDate(newDateTime);
    onTimeChange?.(newDateTime);
  };

  const hours = Array.from({ length: 12 }, (_, i) => i);
  const handleDateSelect = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      const selectedDateTime = DateTime.fromJSDate(selectedDate);
      if (isActiveStartDate(selectedDateTime)) {
        const roundedMinutes =
          disabled.before.minute % 5 === 0
            ? (disabled.before.minute / 5 + 1) * 5
            : Math.ceil(disabled.before.minute / 5) * 5;
        const newSelectedDateTime = selectedDateTime.set({
          hour:
            roundedMinutes >= 60
              ? disabled.before.hour + 1
              : disabled.before.hour,
          minute: roundedMinutes >= 60 ? 0 : roundedMinutes,
          second: 0,
          millisecond: 0,
        });
        setUpNewDateTime(newSelectedDateTime);
      } else {
        setUpNewDateTime(DateTime.fromJSDate(selectedDate));
      }
    }
  };

  const handleTimeChange = (
    type: 'hour' | 'minute' | 'ampm',
    value: string,
  ) => {
    if (date) {
      let newDate = null;

      if (type === 'hour') {
        newDate = date.set({
          hour: (parseInt(value) % 12) + (date.hour >= 12 ? 12 : 0),
        });
      } else if (type === 'minute') {
        newDate = date.set({ minute: parseInt(value) });
      } else if (type === 'ampm') {
        const currentHours = date.hour;
        newDate = date.set({
          hour: value === '오후' ? currentHours + 12 : currentHours - 12,
        });
      }
      setUpNewDateTime(newDate);
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button
          className={cn(
            'flex items-center gap-2 rounded-lg w-full justify-start text-left font-normal border-solid text-my-gray-10 text-label-lg-500 border border-neutral-200 bg-white hover:bg-neutral-100 hover:text-neutral-900 dark:border-neutral-800 dark:bg-neutral-950 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
            !date && 'text-muted-foreground',
            isOpen
              ? 'border-[2px] border-my-blue-50 p-[calc(0.75rem-1px)]'
              : 'border border-my-gray-80 p-3',
          )}
        >
          <CalendarIcon
            color={'var(--gray-50)'}
            width="1rem"
            height="1rem"
          ></CalendarIcon>
          {date ? (
            format(date.toJSDate(), 'yyyy. MM. dd EEEE aa hh:mm', {
              locale: ko,
            })
          ) : (
            <span className="text-my-gray-60">날짜 및 시간 선택</span>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div
          className="border border-solid rounded-lg sm:flex border-my-gray-70 h-[23.75rem]"
          style={{ boxShadow: '0px 8px 24px 0px rgba(23, 32, 42, 0.16)' }}
        >
          <Calendar
            mode="single"
            selected={date ? date.toJSDate() : null}
            onSelect={handleDateSelect}
            initialFocus
            disabled={{
              before: disabled.before
                ? disabled.before.startOf('day').toJSDate()
                : null,
              after: disabled.after
                ? disabled.after.endOf('day').toJSDate()
                : null,
            }}
          />
          <div className="flex flex-col divide-y sm:flex-row sm:h-full sm:divide-y-0 sm:divide-x">
            <ScrollArea className="w-64 py-4 sm:w-auto">
              <div className="flex px-2 sm:flex-col">
                {hours.map((hour) => {
                  const isDisabledHour =
                    date &&
                    isActiveStartDate(date) &&
                    isBeforeActiveStartTime(
                      date?.hour < 12 ? hour : hour + 12,
                      date.minute,
                    ).isHourBefore;
                  return (
                    <button
                      key={hour}
                      className={`flex items-center justify-center w-10 h-10 rounded-lg text-label-md-500 ${
                        date
                          ? isDisabledHour
                            ? 'text-my-gray-70 cursor-default'
                            : date.hour % 12 === hour % 12
                            ? 'bg-my-blue-50 text-my-gray-100 cursor-pointer'
                            : 'text-my-gray-30 cursor-pointer'
                          : 'text-my-gray-30 cursor-pointer'
                      }`}
                      onClick={
                        isDisabledHour
                          ? null
                          : () => handleTimeChange('hour', hour.toString())
                      }
                    >
                      {hour}
                    </button>
                  );
                })}
              </div>
              <ScrollBar orientation="horizontal" className="sm:hidden" />
            </ScrollArea>
            <ScrollArea className="w-64 py-4 border-l border-solid sm:w-auto border-my-gray-80">
              <div className="flex px-2 sm:flex-col">
                {Array.from({ length: 12 }, (_, i) => i * 5).map((minute) => {
                  const isDisabledMin =
                    date &&
                    isActiveStartDate(date) &&
                    isBeforeActiveStartTime(date.hour, minute).isHourMinBefore;
                  return (
                    <button
                      key={minute}
                      className={`flex items-center justify-center w-10 h-10 rounded-lg text-label-md-500 cursor-pointer ${
                        date
                          ? isDisabledMin
                            ? 'text-my-gray-70 cursor-default'
                            : date.minute === minute
                            ? 'bg-my-blue-50 text-my-gray-100 cursor-pointer'
                            : 'text-my-gray-30 cursor-pointer'
                          : 'text-my-gray-30 cursor-pointer'
                      }`}
                      onClick={
                        isDisabledMin
                          ? null
                          : () => handleTimeChange('minute', minute.toString())
                      }
                    >
                      {minute < 10 ? `0${minute}` : minute}
                    </button>
                  );
                })}
              </div>
              <ScrollBar orientation="horizontal" className="sm:hidden" />
            </ScrollArea>
            <ScrollArea className="py-4 border-l border-solid sm:w-auto border-my-gray-80">
              <div className="flex px-2 sm:flex-col">
                {['오전', '오후'].map((ampm) => {
                  const selectedDateIsAm = date && date.hour < 12;
                  const selectedDateIsPm = date && date.hour >= 12;
                  const amIsDisabled =
                    ampm === '오전' &&
                    disabled.before &&
                    date?.hasSame(disabled.before, 'day') &&
                    disabled.before.hour >= 12;
                  const pmIsDisabled =
                    ampm === '오후' &&
                    disabled.after &&
                    date?.hasSame(disabled.after, 'day') &&
                    disabled.after.hour < 12;
                  const disabledOnClick =
                    amIsDisabled || pmIsDisabled
                      ? true
                      : (ampm === '오전' && selectedDateIsAm) ||
                        (ampm === '오후' && selectedDateIsPm);
                  return (
                    <button
                      key={ampm}
                      className={`flex items-center justify-center w-10 h-10 rounded-lg text-label-md-500 cursor-pointer ${
                        (ampm === '오전' && selectedDateIsAm) ||
                        (ampm === '오후' && selectedDateIsPm)
                          ? 'bg-my-blue-50 text-my-gray-100'
                          : 'text-my-gray-30'
                      } ${
                        (amIsDisabled || pmIsDisabled) &&
                        'text-my-gray-70 cursor-default'
                      }`}
                      onClick={
                        disabledOnClick
                          ? null
                          : () => handleTimeChange('ampm', ampm)
                      }
                    >
                      {ampm}
                    </button>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
