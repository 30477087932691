import AddIcon from '@asset/svg/AddIcon';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { toast } from 'react-toastify';
import DeleteXIcon from '@public/common/icon/delete_x_icon.svg';
import * as S from '@styles/common/form/AttachImageForm.style';

const AttachImageForm = ({
  isEditMode,
  fileList,
  setFileList,
}: {
  isEditMode: boolean;
  fileList: File[];
  setFileList;
}) => {
  const { t, ready } = useTranslation('modal');
  const IMAGE_ATTACH_LIMIT = 9;

  if (isEditMode) {
    return (
      <S.AttachImageFormLayout>
        <div className={'title'}>
          {ready && t('modal:teachingNote.form.commentImg.name')}
        </div>
        <S.ImageList>
          <input
            type="file"
            id="add-image"
            accept=".svg, .jpg, .png, .jpeg"
            multiple
            onChange={(e) => {
              if (e.target.files.length > 0) {
                if (
                  e.target.files.length + fileList.length <=
                  IMAGE_ATTACH_LIMIT
                ) {
                  setFileList((prev) => [...prev, ...e.target.files]);
                } else {
                  toast.info(
                    `이미지는 ${IMAGE_ATTACH_LIMIT}장까지만 첨부할 수 있습니다.`,
                  );
                }
              }
            }}
          />
          <label
            className={'add-image-box'}
            htmlFor="add-image"
            onClick={(e) => {
              if (fileList.length >= IMAGE_ATTACH_LIMIT) {
                e.preventDefault();
                toast.info(
                  `이미지는 ${IMAGE_ATTACH_LIMIT}장까지만 첨부할 수 있습니다.`,
                );
              }
            }}
          >
            <div>
              <AddIcon width="1.5rem" height="1.5rem" color="var(--gray-10)" />
              {`${fileList.length}/${IMAGE_ATTACH_LIMIT}`}
            </div>
          </label>
          {fileList?.map((src, index) => {
            return (
              <div key={index} className={'before-attached-image'}>
                <div className="image-wrapper">
                  <Image
                    src={URL.createObjectURL(src)}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <div className="delete-button">
                  <Image
                    src={DeleteXIcon}
                    width={16}
                    height={16}
                    style={{
                      zIndex: 5,
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      const newList = [...fileList];
                      setFileList(newList.filter((value) => value !== src));
                    }}
                  />
                </div>
              </div>
            );
          })}
        </S.ImageList>
      </S.AttachImageFormLayout>
    );
  }
  if (fileList?.length > 0) {
    return (
      <S.AttachImageFormLayout>
        <div className="title">
          {ready && t('modal:teachingNote.form.commentImg.name')}
        </div>
        <S.ImageList>
          <PhotoProvider maskOpacity={0.8}>
            {fileList?.map((src, index) => {
              const imageURL = URL.createObjectURL(src);
              return (
                <PhotoView src={imageURL} key={index}>
                  <div className="image-wrapper">
                    <Image src={imageURL} layout="fill" objectFit="cover" />
                  </div>
                </PhotoView>
              );
            })}
          </PhotoProvider>
        </S.ImageList>
      </S.AttachImageFormLayout>
    );
  }
  return <></>;
};

export default AttachImageForm;
