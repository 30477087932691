import React from 'react';

const GoogleMeetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <g clipPath="url(#clip0_4461_36761)">
        <path
          d="M11.3143 9.00016L13.264 11.2287L15.8857 12.9042L16.3428 9.01388L15.8857 5.21045L13.2137 6.68245L11.3143 9.00016Z"
          fill="#00832D"
        />
        <path
          d="M0 12.5429V15.8572C0 16.6149 0.613714 17.2286 1.37143 17.2286H4.68571L5.37143 14.7235L4.68571 12.5429L2.41143 11.8572L0 12.5429Z"
          fill="#0066DA"
        />
        <path
          d="M4.68571 0.771484L0 5.4572L2.41143 6.14291L4.68571 5.4572L5.36 3.30634L4.68571 0.771484Z"
          fill="#E94235"
        />
        <path d="M4.68571 5.45728H0V12.543H4.68571V5.45728Z" fill="#2684FC" />
        <path
          d="M18.88 2.75543L15.8857 5.21029V12.904L18.8937 15.3703C19.344 15.7223 20.0023 15.4011 20.0023 14.8286V3.28572C20.0023 2.70629 19.3291 2.38858 18.88 2.75543ZM11.3143 9V12.5429H4.6857V17.2286H14.5143C15.272 17.2286 15.8857 16.6149 15.8857 15.8571V12.904L11.3143 9Z"
          fill="#00AC47"
        />
        <path
          d="M14.5143 0.771484H4.6857V5.4572H11.3143V9.00006L15.8857 5.21263V2.14291C15.8857 1.3852 15.272 0.771484 14.5143 0.771484Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <clipPath id="clip0_4461_36761">
          <rect
            width="20"
            height="16.4571"
            fill="white"
            transform="translate(0 0.771484)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleMeetIcon;
