import React from 'react';

const CornerDownRightIcon = ({
  width = '1rem',
  height = '1rem',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 2C3.03486 2 3.33333 2.29848 3.33333 2.66667V3.6C3.33333 4.73111 3.33385 5.53934 3.38562 6.1729C3.43674 6.79869 3.53453 7.19512 3.69665 7.5133C4.01622 8.14051 4.52616 8.65044 5.15337 8.97002C5.47154 9.13214 5.86798 9.22992 6.49376 9.28105C7.12733 9.33282 7.93556 9.33333 9.06667 9.33333H11.7239L9.5286 7.13807C9.26825 6.87772 9.26825 6.45561 9.5286 6.19526C9.78895 5.93491 10.2111 5.93491 10.4714 6.19526L13.8047 9.5286C14.0651 9.78895 14.0651 10.2111 13.8047 10.4714L10.4714 13.8047C10.2111 14.0651 9.78895 14.0651 9.5286 13.8047C9.26825 13.5444 9.26825 13.1223 9.5286 12.8619L11.7239 10.6667H9.03708C7.94204 10.6667 7.0794 10.6667 6.38519 10.61C5.67695 10.5521 5.08551 10.4319 4.54804 10.158C3.66995 9.71062 2.95605 8.99671 2.50864 8.11862C2.23478 7.58115 2.11458 6.98972 2.05671 6.28148C1.99999 5.58727 2 4.72464 2 3.62961L2 2.66667C2 2.29848 2.29848 2 2.66667 2Z"
        fill={color}
      />
    </svg>
  );
};

export default CornerDownRightIcon;
