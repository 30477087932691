import { getMyClassApi } from '@apis/api/teacher/class';
import { useUserInfoQuery } from '@apis/query/user';
import { StudyContentType } from '@asset/enum/materialEnum';
import {
  getStudentStudyStatus,
  translateStudyStatusStepEngToKor,
} from '@asset/function/commonFunctions';
import Tag from '@components/common/Tag';
import {
  CommonListColumnData,
  ListFilter,
} from '@components/common/list/CommonList';
import CommonList from '@components/common/list/CommonList';
import BasicArrowTooltip from '@components/common/tool-tip/BasicArrowTooltip';
import { useMyClassFilteringQueryStore } from '@store/filteringQueryStore';
import { useRouter } from 'next/router';
import {
  myClassListKey,
  MY_CLASS_PATH_QUERY,
  isRouterHasFilteringQuery,
} from 'pages/manage/my-class/[...myClassType]';
import { ParsedUrlQueryInput } from 'querystring';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MyClassTextButton from './MyClassTextButton';
import { AssignmentIcon } from '@asset/svg/AssignmentIcon';
import { convertTimezone } from '@asset/function/i18n';
import { TriangleErrorIcon } from '@asset/svg/TriangleErrorIcon';
import MyClassStudentInfoCell from './MyClassStudentInfoCell';
import MyClassMissionStatusCell from './MyClassMissionStatusCell';
import MyClassStudyTimeStatusCell from './MyClassStudyTimeStatusCell';
import { getManageStudentStudyContentUrlObject } from 'routes/studyContent';
import {
  convertOrderByQueries,
  joinElementsInArrayWithEncoding,
} from '@asset/function/queryStringFunctions';
import { useQuery } from 'react-query';
import { getStudyReportUrlObject } from 'routes/studyReport';
import { getStudentsExtrasApi } from '@apis/api/student/common';
import { DateTime } from 'luxon';

const MyClassMainPage = ({ filteringStudentList, filteringTeacherList }) => {
  const router = useRouter();

  const { data: user } = useUserInfoQuery();
  const {
    urlQueryObj: myClassUrlQueryObj,
    setUrlQueryObj: setMyClassUrlQueryObj,
  } = useMyClassFilteringQueryStore();
  const { register, handleSubmit } = useForm();

  const columnData: CommonListColumnData[] = [
    {
      width: '10rem',
      text: '학생',
      id: myClassListKey.STUDENT,
      filterList: filteringStudentList,
      filterType: ListFilter.MULTI_SEARCH,
    },
    {
      width: '10rem',
      text: '상태',
      id: myClassListKey.STUDY_STATUS,
    },
    {
      width: '6rem',
      text: '출결',
      id: myClassListKey.ATTENDANCE,
      filterList: [
        { id: 'attend', name: '등원' },
        { id: 'home', name: '하원' },
        { id: 'notAttend', name: '미출석' },
      ],
      filterType: ListFilter.MULTI_SEARCH,
    },
    {
      width: '15rem',
      text: '미션',
      id: myClassListKey.MISSION_STATUS,
    },
    {
      width: '15rem',
      text: '학습 시간',
      id: myClassListKey.STUDY_TIME_STATUS,
    },
    {
      width: '5rem',
      text: '질문',
      id: myClassListKey.QUESTION,
      filterType: ListFilter.SORT,
    },
    {
      width: '6rem',
      text: '미채점',
      id: myClassListKey.SCORE,
      filterType: ListFilter.SORT,
    },
    {
      width: '7.5rem',
      text: '주간 리포트',
      id: myClassListKey.WEEKLY_REPORT,
      filterType: ListFilter.SORT,
    },
    {
      width: '7rem',
      text: '등원 일시',
      id: myClassListKey.ATTENDANCE_TIME,
      filterType: ListFilter.SORT,
    },
    {
      width: '7.5rem',
      text: '선생님',
      id: myClassListKey.TEACHER,
      filterList: filteringTeacherList,
      filterType: ListFilter.MULTI_SEARCH,
    },
  ];

  const getIsAttendStatus = (attendInfo: {
    attendTime: string;
    homeTime: string;
  }) => {
    if (attendInfo?.homeTime) {
      return 'home';
    } else if (attendInfo?.attendTime) {
      return 'attend';
    }
    return 'not-attend';
  };

  const { data: myClassData, isFetching } = useQuery(
    ['GET_MY_CLASS_DATA', router.query],
    async () => {
      if (router.query) {
        const keyMap = {
          [myClassListKey.ATTENDANCE_TIME]: myClassListKey.ATTENDANCE_TIME,
          [myClassListKey.SCORE]: myClassListKey.SCORE,
          [myClassListKey.QUESTION]: myClassListKey.QUESTION,
          [myClassListKey.WEEKLY_REPORT]: 'weeklyStudyReportId',
        };
        const orderByQueries = convertOrderByQueries(keyMap, router.query);
        orderByQueries.push('studentName');
        const queryStringObj = {
          studentIdList: router.query[myClassListKey.STUDENT],
          attendTypeList: router.query[myClassListKey.ATTENDANCE],
          teacherIdList: router.query[myClassListKey.TEACHER],
          page: router.query.pageNumber ?? null,
          pageSize: 15,
          orderBy: joinElementsInArrayWithEncoding(orderByQueries),
          search: router.query.searchValue ?? null,
        };
        setMyClassUrlQueryObj(router.query);
        const myClassResponse = await getMyClassApi(queryStringObj);
        if (myClassResponse.classroomTableData.length > 0) {
          const myClassStudentIds = myClassResponse.classroomTableData.map(
            (classroom) => classroom.studentInfo.studentId,
          );
          const studentInfos = await getStudentsExtrasApi({
            studentIds: myClassStudentIds,
          });
          if (studentInfos.success) {
            myClassResponse.classroomTableData.map(
              (classroom) =>
                (classroom.studentInfo['avatarName'] = studentInfos.data.find(
                  (student) =>
                    student.studentId === classroom.studentInfo.studentId,
                )?.avatarName),
            );
          }
        }
        return myClassResponse;
      }
    },
    {
      keepPreviousData: true,
      enabled: isRouterHasFilteringQuery(router.query),
    },
  );

  useEffect(() => {
    if (
      user &&
      router.isReady &&
      (router.query?.[MY_CLASS_PATH_QUERY] as object)[0] === 'main'
    ) {
      if (!isRouterHasFilteringQuery(router.query)) {
        if (
          !myClassUrlQueryObj ||
          Object.keys(myClassUrlQueryObj).length === 0
        ) {
          router.replace({
            pathname: router.asPath,
            query: { [myClassListKey.TEACHER]: user.id },
          });
        } else {
          router.replace({
            pathname: router.asPath,
            query: myClassUrlQueryObj as ParsedUrlQueryInput,
          });
        }
      }
    }
  }, [user]);

  useEffect(() => {
    return () => {
      useMyClassFilteringQueryStore.persist.rehydrate();
    };
  }, []);

  return (
    <CommonList
      title={'나의 교실'}
      columnData={columnData}
      rowData={myClassData ? myClassData.classroomTableData : []}
      pageData={myClassData ? myClassData.pagination : null}
      customColumn={[
        {
          id: myClassListKey.STUDENT,
          type: 'element',
          renderComponent: (rowData) => (
            <MyClassStudentInfoCell student={rowData[myClassListKey.STUDENT]} />
          ),
        },
        {
          id: myClassListKey.STUDY_STATUS,
          type: 'element',
          renderComponent: (rowData) => {
            const studentData = rowData[myClassListKey.STUDENT];
            const studyStatusData = rowData[myClassListKey.STUDY_STATUS];
            const attendData = rowData[myClassListKey.ATTENDANCE];
            const isAttend = getIsAttendStatus(attendData);
            const {
              color: statusColor,
              timeText: timeStatusText,
              statusText,
            } = getStudentStudyStatus(
              studyStatusData?.studentCompletedAt
                ? DateTime.fromISO(studyStatusData?.studentCompletedAt)
                : null,
              isAttend !== 'not-attend',
              studyStatusData?.elapsedTime ?? 0,
              studyStatusData?.studyItemType ?? null,
            );

            return (
              <div className={`flex items-center w-fit min-w-fit`}>
                {statusColor && (
                  <>
                    <div
                      className={`min-w-[0.5rem] min-h-2 w-2 h-2 rounded-[50%] mr-2`}
                      style={{ background: statusColor }}
                    ></div>
                    <div
                      className={`text-label-sm-500 text-my-gray-50 whitespace-nowrap`}
                    >
                      {timeStatusText}&nbsp;
                      {statusText && '-'}&nbsp;
                    </div>
                    <BasicArrowTooltip
                      arrow
                      placement="top"
                      title={
                        studyStatusData
                          ? `${studyStatusData.course} / ${
                              studyStatusData.materialType
                            } / ${studyStatusData.materialName} | ${
                              studyStatusData.indexNameType
                            } ${studyStatusData.indexNumber} / ${
                              studyStatusData.division
                            } ${
                              studyStatusData.subIndexNumber
                            } | ${translateStudyStatusStepEngToKor(
                              studyStatusData.studyStatusType,
                            )}`
                          : ''
                      }
                      disableHoverListener={studyStatusData === null}
                    >
                      <div
                        className={`relative underline cursor-pointer text-label-sm-500 text-my-blue-50 break-keep`}
                        onClick={(e) => {
                          router.push(
                            getManageStudentStudyContentUrlObject(
                              studyStatusData.assignmentId
                                ? StudyContentType.MY_ASSIGNMENT
                                : studyStatusData.myStudyContentType ===
                                    StudyContentType.MY_WRONG_TEST ||
                                  studyStatusData.myStudyContentType ===
                                    StudyContentType.MY_LEVEL_TEST
                                ? StudyContentType.MY_TEST
                                : studyStatusData.myStudyContentType,
                              studyStatusData.assignmentId
                                ? studyStatusData.assignmentId
                                : studyStatusData.myStudyContentId,
                              {
                                studentId: studentData.studentId,
                                modalType: 'studentAnswer',
                                pageId: studyStatusData.pageId,
                                studyItemType: studyStatusData.studyItemType,
                                problemStatusId:
                                  studyStatusData.problemStatusId,
                                problemStatusDetailId:
                                  studyStatusData.problemStatusDetailId,
                              },
                            ),
                          );
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {statusText}
                      </div>
                    </BasicArrowTooltip>
                  </>
                )}
              </div>
            );
          },
        },
        {
          id: myClassListKey.ATTENDANCE,
          type: 'element',
          renderComponent: (rowData) => {
            const attendStatus = getIsAttendStatus(
              rowData[myClassListKey.ATTENDANCE],
            );
            if (attendStatus === 'attend') {
              return <Tag label={'등원'} color={'light-blue'} />;
            } else if (attendStatus === 'home') {
              return <Tag label={'하원'} color={'light-orange'} />;
            } else {
              return <Tag label={'미출석'} color={'light-gray'} />;
            }
          },
        },
        {
          id: myClassListKey.MISSION_STATUS,
          type: 'element',
          renderComponent: (rowData) => (
            <MyClassMissionStatusCell
              studentData={rowData[myClassListKey.STUDENT]}
              missionData={rowData[myClassListKey.MISSION_STATUS]}
            />
          ),
        },
        {
          id: myClassListKey.STUDY_TIME_STATUS,
          type: 'element',
          renderComponent: (rowData) => (
            <MyClassStudyTimeStatusCell
              studyTimeData={rowData[myClassListKey.STUDY_TIME_STATUS]}
            />
          ),
        },
        {
          id: myClassListKey.QUESTION,
          type: 'element',
          renderComponent: (rowData) => {
            const studentData = rowData[myClassListKey.STUDENT];
            const questionData = rowData[myClassListKey.QUESTION];
            return (
              <MyClassTextButton
                content={questionData > 0 ? questionData : '-'}
                canHover={Boolean(questionData > 0)}
                onClickEvent={
                  questionData > 0
                    ? () =>
                        router.push(
                          `/manage/student/question/list?studentName=${studentData.studentId}&status=not-resolved`,
                        )
                    : null
                }
              ></MyClassTextButton>
            );
          },
        },
        {
          id: myClassListKey.SCORE,
          type: 'element',
          renderComponent: (rowData) => {
            const studentData = rowData[myClassListKey.STUDENT];
            const scoreData = rowData[myClassListKey.SCORE];
            return (
              <MyClassTextButton
                content={scoreData > 0 ? scoreData : '-'}
                canHover={Boolean(scoreData > 0)}
                onClickEvent={
                  scoreData > 0
                    ? () =>
                        router.push(
                          `/manage/study-status-list/total?studentName=${studentData.studentId}&isCheckAnswer=false`,
                        )
                    : null
                }
              ></MyClassTextButton>
            );
          },
        },
        {
          id: myClassListKey.WEEKLY_REPORT,
          type: 'element',
          renderComponent: (rowData) => {
            const studentData = rowData[myClassListKey.STUDENT];
            const reportData = rowData[myClassListKey.WEEKLY_REPORT];
            return (
              <MyClassTextButton
                content={
                  reportData ? <AssignmentIcon /> : <TriangleErrorIcon />
                }
                canHover={true}
                onClickEvent={
                  reportData
                    ? () =>
                        router.push(
                          getStudyReportUrlObject(reportData.reportId, {
                            recipientId: reportData.parentRecipientId,
                          }),
                        )
                    : () =>
                        router.push(
                          `/manage/dashboard/my-study-records/${studentData.studentId}`,
                        )
                }
                tooltipTitle={reportData ? '리포트 확인' : '주간 리포트 작성'}
              ></MyClassTextButton>
            );
          },
        },
        {
          id: myClassListKey.ATTENDANCE_TIME,
          type: 'element',
          renderComponent: (rowData) => {
            const attendanceData = rowData[myClassListKey.ATTENDANCE_TIME];
            return (
              <div>
                {attendanceData
                  ? convertTimezone(attendanceData, null, false).format(
                      'MM.DD HH:mm',
                    )
                  : '-'}
              </div>
            );
          },
        },
        {
          id: myClassListKey.TEACHER,
          type: 'element',
          renderComponent: (rowData) => {
            const studentData = rowData[myClassListKey.STUDENT];
            return <div>{studentData.teacherName}</div>;
          },
        },
      ]}
      searchForm={{
        searchSubmit: handleSubmit,
        searchRegister: register,
        placeholder: '검색',
      }}
      isLoading={isFetching}
    ></CommonList>
  );
};

export default MyClassMainPage;
