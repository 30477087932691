import React from 'react';

const AnnouncementIcon = ({
  width = '1rem',
  height = '1rem',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.1251 2.16671C13.0069 1.77484 14 2.42025 14 3.38513V10.6151C14 11.58 13.0069 12.2254 12.1251 11.8335L8.12928 10.0576C8.04404 10.0197 7.9518 10.0001 7.85852 10.0001H6.66667V13.3334C6.66667 13.7016 6.36819 14.0001 6 14.0001H4.66667C4.29848 14.0001 4 13.7016 4 13.3334V10.0001H3.33333C2.59695 10.0001 2 9.40316 2 8.66678V5.33344C2 4.59707 2.59695 4.00011 3.33333 4.00011H7.85852C7.9518 4.00011 8.04404 3.98054 8.12928 3.94265L12.1251 2.16671Z"
        fill={color}
      />
    </svg>
  );
};

export default AnnouncementIcon;
