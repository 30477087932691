import { MUI_MODAL_Z_INDEX } from './common';

export enum ChatMessageType {
  GOOGLE_MEET = 'google-meet',
  FILE = 'file',
  SCHEDULED = 'scheduled',
  QUESTION = 'question',
}

export enum ChatMessageMenu {
  REPLY = 'reply',
  ANNOUNCE = 'announce',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const customStringSet = {
  QUOTE_MESSAGE_INPUT__REPLY_TO: '',
  QUOTED_MESSAGE__CURRENT_USER: '나',
  QUOTED_MESSAGE__REPLIED_TO: '에게 답장',
  TYPING_INDICATOR__IS_TYPING: '입력 중 ...',
  TYPING_INDICATOR__AND: ', ',
  TYPING_INDICATOR__ARE_TYPING: '입력 중 ...',
  TYPING_INDICATOR__MULTIPLE_TYPING: '입력 중 ...',
  MESSAGE_INPUT__PLACE_HOLDER: '메시지를 입력하세요',
  MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: '메시지를 입력하세요',
  MESSAGE_EDITED: '(수정됨)',
  BUTTON__SUBMIT: '제출',
  BUTTON__CANCEL: '취소',
  BUTTON__DELETE: '삭제',
  BUTTON__SAVE: '완료',
  BUTTON__CREATE: '생성',
  BUTTON__INVITE: '초대',
  BUTTON__OK: '확인',
  MODAL__DELETE_MESSAGE__TITLE: '메시지 삭제',
  MESSAGE_MENU__DELETE: '삭제',
};

export const CHAT_ROOM_Z_INDEX = MUI_MODAL_Z_INDEX + 1;
