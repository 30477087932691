import styled from 'styled-components';
import tw from 'twin.macro';

export const GnbLayout = styled.nav`
  & {
    ${tw`z-[50] items-center justify-between w-full px-6`}
    display:  ${(props) => (props.display ? props.display : 'flex')};
    grid-template-columns: 1fr 3fr 1fr;
    height: var(--height-global-nav);
    background-color: var(--gray-20);
    border-bottom: 1px solid var(--gray-30);
  }
`;

export const GnbMainLogoWrapper = styled.div`
  & {
    ${tw`flex items-center justify-start gap-12 mr-12`}

    .object-container {
      width: 11.125rem;
      position: relative;
      a {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        top: 0px;
        left: 0px;
      }
      object {
        width: 100%;
      }
    }

    > span {
      color: var(--old-navy);
    }
  }
`;

export const GnbMenuItemContainer = styled.ul`
  & {
    ${tw`flex m-0`}
    gap: ${(props) => (props.gap ? props.gap : '1.5rem')};
    flex: 1;
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : 'start'};
    > a {
      ${tw`flex items-center gap-2 px-[0.625rem] py-5 text-label-lg-700 text-my-gray-60`}

      &:hover {
        color: var(--gray-100);
        svg {
          > path {
            fill: var(--gray-100);
          }
        }
      }

      &.picked {
        ${tw`text-label-lg-700 text-my-gray-100`}
        svg {
          > path {
            fill: var(--gray-100);
          }
        }
      }
    }
  }
`;

export const GnbUserInfoSection = styled.div`
  & {
    ${tw`relative flex items-center justify-end gap-4`}

    >button {
      ${tw`flex`}
    }
  }
`;

export const StudentListDropDownContainer = styled.div`
  & {
    ${tw`flex items-center gap-2 cursor-pointer`}
    p {
      ${tw`text-gray-100 text-label-lg-500`}
    }
  }
`;
